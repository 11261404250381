import dayjs from "dayjs";
import i18n from "../config/i18n";
import { CHAT_STARTED, SCENE_CHANGED } from "./events";
import utils from "./utils";
import {Speech, PlayList} from "./speech";

export default class VirtualGuide {
  constructor(chat, activityTracker, walk, config) {
    this.config = config;
    this.chat = chat;
    this.activityTracker = activityTracker;
    this.messages = {};
    this.systemMessagesTimeouts = [];
    this.language = utils.getProjectCreationLanguage();
    this.isGuideActive = true;

    this.startTime = dayjs();
    this.systeMessage1PhotoId = null;
    this.systeMessage2PhotoId = null;
    this.systeMessage3PhotoId = null;

    this.fetchGuideMessages();
    this.playList = new PlayList();
    this.speech = new Speech(config);
    if (config?.UserName) {
      setTimeout(() => {
        chat.onMessage({
          message: i18n
            .t("txtVirtualGuideWelcomeMessage", { lng: this.language })
            .replace("<username>", config?.UserName),
          sent: new Date().toISOString(),
          sentByClient: true,
          userName: config?.UserName,
          type: "tip",
          isSystemMessage: true,
          hideAfter: 10000,
        });
      }, 1000);
    }

    if (
      config.VirtualGuideMessages.Photos.filter(
        (photo) => !!photo.Messages.length
      ).length < 3
    ) {
      this.isGuideActive = false;
    }

    const firstPhotoId = config.StartingPhotoId || config.Photos?.[0].Id;

    this.systemMessagesTimeouts.push(
      setTimeout(() => {
        if (
          this.isGuideActive &&
          !activityTracker.isChatStarted &&
          firstPhotoId in this.messages &&
          this.messages[firstPhotoId].length &&
          this.messages[firstPhotoId][0].Content
        ) {
          const showMessage = (pid, duration) => chat.onMessage({
            message: this.messages[pid][0]?.Content,
            sent: new Date().toISOString(),
            sentByClient: true,
            userName: config?.UserName,
            type: "tip",
            isSystemMessage: true,
            hideAfter: duration,
          });
          this.applyLectorIfNeeded(firstPhotoId, showMessage);
        }
      }, 10000)
    );

    if (this.isGuideActive) {
      setTimeout(async () => {
        this.sendSystemMessage1();
        this.systeMessage1PhotoId = activityTracker.currentSceneId;
      }, 25000);
    }

    setTimeout(async () => {
      if (
        this.isGuideActive &&
        activityTracker.currentSceneId !== this.systeMessage1PhotoId
      ) {
        this.sendSystemMessage2();
        this.systeMessage2PhotoId = activityTracker.currentSceneId;
      }
    }, 60000);

    setTimeout(async () => {
      if (
        this.isGuideActive &&
        this.systeMessage2PhotoId != null &&
        activityTracker.currentSceneId !== this.systeMessage2PhotoId
      ) {
        this.sendSystemMessage3();
        this.systeMessage3PhotoId = activityTracker.currentSceneId;
      }
    }, 90000);

    activityTracker.afterLastActivity(
      null,
      () => {
        if (
          !activityTracker.isMediaPreviewOpen &&
          !activityTracker.isChatStarted &&
          this.isGuideActive
        ) {
          const room = activityTracker.getUnvisitedRoom();
          if (room) {
            chat.onMessage({
              message: i18n
                .t("txtVirtualGuideVisitRoomSuggestion", { lng: this.language })
                .replace("<room>", room.Name),
              sent: new Date().toISOString(),
              sentByClient: true,
              userName: config?.UserName,
              disableAutoHiding: true,
              onClick: () => {
                walk.switchSceneByUser(room, null, true);
              },
              type: "tip",
              isSystemMessage: true,
            });
          }
        }
      },
      30000,
      false
    );

    activityTracker.afterLastActivity(SCENE_CHANGED, ({ photoId }) => {
      
      this.systemMessagesTimeouts.push(
        setTimeout(() => {
          if (
            !activityTracker.isChatStarted &&
            !activityTracker.wasVisited(photoId) &&
            photoId in this.messages &&
            this.messages[photoId].length &&
            this.messages[photoId][0].Content &&
            this.isGuideActive
          ) {
            const showMessage = (pid, duration) => chat.onMessage({
              message: this.messages[pid][0]?.Content,
              sent: new Date().toISOString(),
              sentByClient: true,
              userName: config?.UserName,
              type: "tip",
              isSystemMessage: true,
              hideAfter: duration,
            });
            this.applyLectorIfNeeded(photoId, showMessage);
          }
        }, 3000)
      );

      if (
        this.systeMessage2PhotoId == null &&
        dayjs().diff(this.startTime, "s") > 60 &&
        photoId !== this.systeMessage1PhotoId
      ) {
        this.systemMessagesTimeouts.push(
          setTimeout(() => {
            this.sendSystemMessage2();
            this.systeMessage2PhotoId = activityTracker.currentSceneId;
          }, 10000)
        );
      } else if (
        this.systeMessage2PhotoId != null &&
        this.systeMessage3PhotoId == null &&
        dayjs().diff(this.startTime, "s") > 90 &&
        photoId !== this.systeMessage2PhotoId
      ) {
        this.systemMessagesTimeouts.push(
          setTimeout(() => {
            this.sendSystemMessage3();
            this.systeMessage3PhotoId = activityTracker.currentSceneId;
          }, 10000)
        );
      }
    });

    activityTracker.afterLastActivity(CHAT_STARTED, () => {
      this.clearSystemMessagesTimeouts();
      this.playList.clearQueue();
      this.speech.stop();
    });
  }

  applyLectorIfNeeded(photoId, showMessage) {
    if(this.config?.Lector) {
      this.playList.addToQueue({
        id: photoId,
        action: (pid) => {
          const duration = this.speech.play(pid);
          showMessage(pid, duration);
          return duration;
        }
      });
    } else {
      showMessage(photoId, 10000);
    }
  }

  async sendSystemMessage1() {
    if (this.activityTracker.isChatStarted || !this.isGuideActive) return;
    this.chat.onMessage({
      message: i18n.t("txtVirtualGuideSystemMessage1Question", {
        lng: this.language,
      }),
      sent: new Date().toISOString(),
      sentByClient: true,
      userName: this.config?.UserName,
      hideAfter: 6000,
      type: "tip",
      withQuestion: true,
      isSystemMessage: true,
      positiveButtonAction: async () => {
        this.handleActionButtonClick.bind(
          this,
          await this.getReponseTextDependingOnAgentStatus(
            "txtVirtualGuideSystemMessage1PositiveResponse"
          )
        );
      },
      negativeButtonAction: async () => {
        this.handleActionButtonClick.bind(
          this,
          await this.getReponseTextDependingOnAgentStatus(
            "txtVirtualGuideSystemMessage1NegativeResponse"
          ),
          false,
          true
        );
        this.noteVirtualGuideInteraction();
      },
      positiveButtonText: i18n.t(
        "txtVirtualGuideSystemMessage1PositiveButton",
        { lng: this.language }
      ),
      negativeButtonText: i18n.t(
        "txtVirtualGuideSystemMessage1NegativeButton",
        { lng: this.language }
      ),
    });
  }

  async sendSystemMessage2() {
    if (this.activityTracker.isChatStarted || !this.isGuideActive) return;
    this.chat.onMessage({
      message: i18n.t("txtVirtualGuideSystemMessage2Question", {
        lng: this.language,
      }),
      sent: new Date().toISOString(),
      sentByClient: true,
      userName: this.config?.UserName,
      hideAfter: 6000,
      type: "tip",
      isSystemMessage: true,
      withQuestion: true,
      positiveButtonAction: this.handleActionButtonClick.bind(
        this,
        await this.getReponseTextDependingOnAgentStatus(
          "txtVirtualGuideSystemMessage2PositiveResponse"
        ),
        true
      ),
      negativeButtonAction: this.handleActionButtonClick.bind(
        this,
        await this.getReponseTextDependingOnAgentStatus(
          "txtVirtualGuideSystemMessage2NegativeResponse"
        )
      ),
      positiveButtonText: i18n.t(
        "txtVirtualGuideSystemMessage2PositiveButton",
        { lng: this.language }
      ),
      negativeButtonText: i18n.t(
        "txtVirtualGuideSystemMessage2NegativeButton",
        { lng: this.language }
      ),
    });
  }

  async sendSystemMessage3() {
    if (this.activityTracker.isChatStarted || !this.isGuideActive) return;
    this.chat.onMessage({
      message: i18n.t("txtVirtualGuideSystemMessage3Question", {
        lng: this.language,
      }),
      sent: new Date().toISOString(),
      sentByClient: true,
      userName: this.config?.UserName,
      hideAfter: 6000,
      type: "tip",
      isSystemMessage: true,
      withQuestion: true,
      positiveButtonAction: this.handleActionButtonClick.bind(
        this,
        await this.getReponseTextDependingOnAgentStatus(
          "txtVirtualGuideSystemMessage3PositiveResponse"
        ),
        true
      ),
      negativeButtonAction: this.handleActionButtonClick.bind(
        this,
        await this.getReponseTextDependingOnAgentStatus(
          "txtVirtualGuideSystemMessage3NegativeResponse"
        ),
        true
      ),
      positiveButtonText: i18n.t(
        "txtVirtualGuideSystemMessage3PositiveButton",
        { lng: this.language }
      ),
      negativeButtonText: i18n.t(
        "txtVirtualGuideSystemMessage3NegativeButton",
        { lng: this.language }
      ),
    });
  }

  async getReponseTextDependingOnAgentStatus(key) {
    const noChatResponse = i18n.t(key, {
      lng: this.language,
    });
    const activeChatResponse = i18n.t(`${key}ActiveChat`, {
      lng: this.language,
    });
    if (activeChatResponse.startsWith("txt")) {
      return noChatResponse;
    } else {
      const isAgentAvailable = await this.getAgentAvailability();
      if (isAgentAvailable) {
        return activeChatResponse;
      } else {
        return noChatResponse;
      }
    }
  }

  async getAgentAvailability() {
    const { isAgentAvailable } = await fetch(
      `${window.apiUrl}/api/forms/${this.chat.folderId}/get-agent-availability`
    ).then((data) => data.json());
    return isAgentAvailable;
  }

  clearSystemMessagesTimeouts() {
    this.systemMessagesTimeouts.forEach((timeout) => {
      clearTimeout(timeout);
    });
  }

  handleActionButtonClick(
    message,
    withContactForm = false,
    stopVirtualGuide = false
  ) {
    if (!message) return;
    if (stopVirtualGuide) {
      this.isGuideActive = false;
    }
    setTimeout(() => {
      this.chat.onMessage(
        {
          message,
          sent: new Date().toISOString(),
          sentByClient: true,
          userName: this.config?.UserName,
          hideAfter: 6000,
          disableAutoHiding: withContactForm,
          type: "tip",
          isSystemMessage: true,
          withContactForm,
        },
        false
      );
    }, 1000);
  }

  fetchGuideMessages() {
    this.messages = this.config?.VirtualGuideMessages?.Photos?.reduce(
      (prev, curr) => {
        prev[curr?.PhotoId] = curr?.Messages;
        return prev;
      },
      {}
    );
  }

  async noteVirtualGuideInteraction() {
    await fetch(
      `${window.apiUrl}/api/project/stats/${this.chat.folderId}/virtual-guide-interact`,
      {
        method: "PATCH",
      }
    );
  }
}
