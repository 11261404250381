"use strict;";

import fontawesome from "@fortawesome/fontawesome";
import faSync from "@fortawesome/fontawesome-free-solid/faSync";
import faSignInAlt from "@fortawesome/fontawesome-free-solid/faSignInAlt";
import faPlay from "@fortawesome/fontawesome-free-solid/faPlay";
import faArrowLeft from "@fortawesome/fontawesome-free-solid/faArrowLeft";
import faArrowRight from "@fortawesome/fontawesome-free-solid/faArrowRight";
import faArrowUp from "@fortawesome/fontawesome-free-solid/faArrowUp";
import faArrowDown from "@fortawesome/fontawesome-free-solid/faArrowDown";
import faPlus from "@fortawesome/fontawesome-free-solid/faPlus";
import faMinus from "@fortawesome/fontawesome-free-solid/faMinus";
import faSpinner from "@fortawesome/fontawesome-free-solid/faSpinner";
import faPlayCircle from "@fortawesome/fontawesome-free-solid/faPlayCircle";
import faCircle from "@fortawesome/fontawesome-free-solid/faCircle";
import faLink from "@fortawesome/fontawesome-free-solid/faLink";
import faUnlink from "@fortawesome/fontawesome-free-solid/faUnlink";
import faPhone from "@fortawesome/fontawesome-free-solid/faPhone";
import faPhoneSlash from "@fortawesome/fontawesome-free-solid/faPhoneSlash";
import faMicrophone from "@fortawesome/fontawesome-free-solid/faMicrophone";
import faMicrophoneSlash from "@fortawesome/fontawesome-free-solid/faMicrophoneSlash";
import faExpand from "@fortawesome/fontawesome-free-solid/faExpand";
import faInfo from "@fortawesome/fontawesome-free-solid/faInfo";
import farCircle from "@fortawesome/fontawesome-free-regular/faCircle";
import faArrowAltCircleRight from "@fortawesome/fontawesome-free-solid/faArrowAltCircleRight";
import faComments from "@fortawesome/fontawesome-free-solid/faComments";
import faTimes from "@fortawesome/fontawesome-free-solid/faTimes";
import faCheck from "@fortawesome/fontawesome-free-solid/faCheck";
import faShoePrints from "@fortawesome/fontawesome-free-solid/faShoePrints";
import faWalking from "@fortawesome/fontawesome-free-solid/faWalking";
import faUserTie from "@fortawesome/fontawesome-free-solid/faUserTie";
import faEnvelope from "@fortawesome/fontawesome-free-solid/faEnvelope";
import faMap from "@fortawesome/fontawesome-free-solid/faMap";
import farSmile from "@fortawesome/fontawesome-free-regular/faSmile";
import faCamera from "@fortawesome/fontawesome-free-solid/faCamera";
import faVolumeDown from "@fortawesome/fontawesome-free-solid/faVolumeDown";
import faVolumeUp from "@fortawesome/fontawesome-free-solid/faVolumeUp";
import faVolumeOff from "@fortawesome/fontawesome-free-solid/faVolumeOff";

fontawesome.library.add(
  faSync,
  faSignInAlt,
  faEnvelope,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faPlus,
  faMinus,
  faSpinner,
  faPlayCircle,
  faCircle,
  faLink,
  faUnlink,
  faPhone,
  faPhoneSlash,
  faMicrophone,
  faMicrophoneSlash,
  faExpand,
  faInfo,
  farCircle,
  faArrowAltCircleRight,
  faComments,
  faTimes,
  faShoePrints,
  faWalking,
  faCheck,
  faPlay,
  faUserTie,
  faMap,
  farSmile,
faCamera,
faVolumeUp,
faVolumeOff,
);

fontawesome.dom.i2svg();
