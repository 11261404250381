//Watcher
import chatTemplate from "App/pages/chat-widget.html";
import leadPageTemplate from "App/pages/leadPage.html";
import Utils from "App/js/utils.js";
import Fullscreen from "./fullscreen";
import PokeWindow from "./poke";
import { HubConnectionBuilder } from "@microsoft/signalr";
import * as dayjs from "dayjs";
import * as EmojiMart from "emoji-mart";
import pl from "@emoji-mart/data/i18n/pl.json";
import en from "@emoji-mart/data/i18n/en.json";
import {
  Bus,
  CONNECTED,
  CONNECTION_FAILURE,
  END_CALL,
  ERROR,
  GOT_REMOTE_STREAM,
  INVOKE,
  NEW_MESSAGE,
} from "./simple-bus";
import { SCENE_CHANGED } from "App/js/events.js";
import LiveChat from "./rtc/live-chat";
import i18n from "../config/i18n";
import { CHAT_STARTED } from "./events";
import utils from "./utils";
import PresentationLobby from "./presentationLobby";
import VirtualPresentation from "./virtualPresentation";

const nameMaxLength = 100;
const msgMaxLength = 300;
const minChatHeight = "42px";
const leadPageConfig = {
  showAfterIdleTime: 45000,
  showAfterBusyTime: 2000,
};
const linkRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

const FIVE_MINUTES = 5 * 60 * 1000;

class Chat {
  constructor(selector, project, folderId, store, from, config) {
    this.minimalMode = config.mls;
    // this.noChat = config.nc || !this.hasFeature(folderId);
    this.noChat = config.nc;
    this.noLead = config.nl;
    //console.log({
    //    project,
    //    projectId
    //})

    this.project = project;
    const projectSplit = project.split("-");
    this.projectId = Number(projectSplit[projectSplit.length - 1]);
    this.presentationId = Utils.getParameterValue("pid", null);
    this.from = from;
    this.roomId = null;
    this.unreadMessageCount = 0;
    this.creationLanguage = utils.getProjectCreationLanguage();
    this.folderId = folderId;
    this.deviceId = localStorage.getItem("sb.deviceId");
    this.getAgentAvailability().then((isAgentAvailable) => {
      if (
        !isAgentAvailable &&
        config?.HasVirtualGuide &&
        config.VirtualGuideMessages.Photos.length >= 3
      ) {
        this.container.querySelector("#contact-form").style.display = "none";
      }
    });

    this.lastMessageDate = null;
    this.messagesInQueue = 0;
    this.lastMessageInQueueDate = null;
    this.delayBetweenMessages = 3; // in seconds
    this.isChatStarted = false;
    this.timeToRemoveNewMessage = null;
    this.userStartedConversation = false;

    if (!this.minimalMode) {
      this.folderId = folderId;

      this.container = document.querySelector(selector);
      this.container.outerHTML = chatTemplate;
      this.container = document.querySelector(selector);

      Utils.translateAttributes(this.container);

      if (!this.noChat || this.presentationId) {
        if (this.presentationId) {
          this.presentationLobby = new PresentationLobby(
            ".presentation-lobby",
            this.onLobbyClose.bind(this)
          );
        }
        // this.message = this.container.querySelector(".currentMessage");
        // this.message.addEventListener("keypress", this.onMessageKeyPressed.bind(this));
        // const sender = this.container.querySelector(".send");
        // sender.addEventListener("click", this.onMessageClicked.bind(this));

        // Chat icon and minimize button events
        this.chatContainer = this.container.querySelector(
          ".chat2__chat-container"
        );

        if (config?.ownerAvatar) {
          const avatar = this.container.querySelector(".chat-btn__avatar");
          avatar.src = config.ownerAvatar;
          Utils.removeClass(avatar, "hidden");
          const icon = this.container.querySelector(".chat-btn__icon");
          Utils.addClass(icon, "hidden");
        }

        const chatBtn = this.container.querySelector(".chat2__btn");
        chatBtn.addEventListener("click", () => {
          if (
            Utils.checkClass(
              this.chatContainer,
              "chat2__chat-container--hidden"
            )
          ) {
            Utils.removeClass(
              this.chatContainer,
              "chat2__chat-container--hidden"
            );
            this.unreadMessageCount = 0;
            Utils.addClass(this.unreadMessageCounter, "hidden");
            this.newMessageList.textContent = "";
          } else {
            Utils.addClass(this.chatContainer, "chat2__chat-container--hidden");
          }
        });
        const minimizeButton = this.container.querySelector("#minimize-chat");
        minimizeButton.addEventListener("click", () => {
          Utils.addClass(this.chatContainer, "chat2__chat-container--hidden");
        });

        // Start chat button event
        const contactForm = this.container.querySelector("#contact-form");
        const contactFormFields = this.container.querySelector(
          "#contact-form-fields"
        );
        contactForm.addEventListener("submit", (e) => {
          e.preventDefault();
          if (Utils.checkClass(contactFormFields, "hidden")) {
            Utils.removeClass(contactFormFields, "hidden");
            contactForm.removeAttribute("novalidate");
          } else {
            this.startChat(e);
          }
        });

        // User data and message forms
        this.userDataForm = this.container.querySelector("#contact-form");
        this.messageForm = this.container.querySelector("#message-form");
        this.messageForm.addEventListener("submit", (e) => {
          e.preventDefault();
          this.sendMessage.call(this);
          if (!this.userStartedConversation) {
            this.userStartedConversation = true;
            this.getAgentAvailability().then((isAgentAvailable) => {
              if (!isAgentAvailable) {
                setTimeout(() => {
                  this.addMessage({
                    message: i18n.t(
                      "txtVirtualGuideStartConversationWithUnavailableAgent",
                      {
                        lng: utils.getProjectCreationLanguage(),
                      }
                    ),
                    roomId: this.roomId,
                    sendByClient: false,
                    sent: new Date().toISOString(),
                    userName: this.config?.UserName,
                  });
                }, 1000);
                this.sendNotificationToAgent(this.phoneNumber);
              }
            });
          }
        });
        // Custom validation messages
        ["phone", "name", "rules"].forEach((name) => {
          const node = this.userDataForm.elements[name];
          node.addEventListener("input", () => {
            node.setCustomValidity("");
            node.checkValidity();
          });

          node.addEventListener("invalid", () => {
            if (
              node.value === "" ||
              (node.type === "checkbox" && !node.checked)
            ) {
              node.setCustomValidity(i18n.t("txtRequiredField"));
            } else {
              node.setCustomValidity(i18n.t("txtInvalidValue"));
            }
          });
        });

        const message = this.messageForm.elements["message"];
        message.addEventListener("input", () => {
          message.setCustomValidity("");
          message.checkValidity();
        });
        message.addEventListener("invalid", () => {
          if (message.value === "") {
            message.setCustomValidity(i18n.t("txtEnterMessage"));
          } else {
            message.setCustomValidity(i18n.t("txtInvalidValue"));
          }
        });

        // Messages
        this.message = this.container.querySelector("#message");
        this.messageList = this.container.querySelector("#message-list");
        this.unreadMessageCounter =
          this.container.querySelector("#unread-messages");
        this.newMessageList = this.container.querySelector("#new-message-list");

        // New message sound
        Bus.subscribe(NEW_MESSAGE, function () {
          var audio = document.querySelector("#notification");
          audio.currentTime = 0;
          audio.play();
        });

        // Emoji
        const emojiBtn = this.container.querySelector("#emoji-picker-btn");
        const pickerOptions = {
          theme: "light",
          skinTonePosition: "none",
          perLine: 8,
          locale: ["pl", "en"].includes(Utils.getProjectLang())
            ? Utils.getProjectLang()
            : "en",
          onEmojiSelect: (e) => {
            this.message.value += e.native;
            this.messageForm.querySelector("em-emoji-picker").remove();
            this.message.focus();
          },
          onClickOutside: () => {
            this.messageForm.querySelector("em-emoji-picker").remove();
          },
        };
        const picker = new EmojiMart.Picker(pickerOptions);
        emojiBtn.addEventListener("click", () => {
          const emojiPickerComponent =
            this.messageForm.querySelector("em-emoji-picker");
          if (emojiPickerComponent) {
            emojiPickerComponent.remove();
          } else {
            this.messageForm.appendChild(picker);
          }
        });

        // this.userPhoto = this.container.querySelector("#user-photo");
        // this.header = this.container.querySelector("#header");
        // this.positionName = this.container.querySelector("#headerPositionName");
        // this.phoneNumber = this.container.querySelector("#headerPhoneNumberPhone");
        // this.contactEmail = this.container.querySelector("#headerContactEmail");
        // this.indicator = this.container.querySelector(".indicator");
        // this.conversation = this.container.querySelector(".conversation");
        // this.conversationError = this.container.querySelector(".conversation-error");

        // this.main = this.container.querySelector(".main-screen");
        // this.sendWrapper = this.main.querySelector(".send-wrapper");
        // this.refreshWrapper = this.main.querySelector(".refresh-wrapper");
        // this.reconnectWrapper = this.main.querySelector(".reconnect-wrapper");
        // this.reconnectEl = this.reconnectWrapper.querySelector(".reconnect");
        // this.reconnectEl.addEventListener("click", this.reconnect.bind(this));
        // this.userName = null;

        // this.minimize = this.container.querySelector("#minimize");
        // this.minimize.addEventListener("click", this.showHideChat.bind(this));

        // this.maximize = this.container.querySelector("#text-chat");
        // this.maximize.addEventListener("click", this.showHideChat.bind(this));
        // this.maximizelabel = this.maximize.querySelector(".maximize-label");
        // this.maximize.addEventListener("click", this.onChatBtnClick.bind(this));

        // this.transmitting = false;
        // this.receiving = false;
        // this.linker = null;

        // this.refresh = this.container.querySelector(".refresh");
        // this.refresh.addEventListener("click", this.onRefreshClick.bind(this));

        // this.rtcActions = this.container.querySelector(".rtc-actions");
        // this.linkEl = this.rtcActions.querySelector(".link");
        // this.linkEl.addEventListener("click", this.onLinkClick.bind(this));
        // this.unlinkEl = this.rtcActions.querySelector(".unlink");
        // this.unlinkEl.addEventListener("click", this.onUnlinkClick.bind(this));
        // Bus.subscribe(SCENE_CHANGED, this.changeScene.bind(this));
        // this.store = store;
        // this.live = new LiveChat(window.hubsUrl);
        // this.remoteVideo = this.rtcActions.querySelector(".remote");
        // this.callEl = this.rtcActions.querySelector(".call");
        // this.callEl.addEventListener("click", this.onCallClick.bind(this));
        // this.hangupEl = this.rtcActions.querySelector(".hangup");
        // this.callIndicator = this.hangupEl.querySelector(".indicator-inner");
        // this.indicatorSpinner = this.callIndicator.querySelector(".connecting");

        // this.pokeWindow = new PokeWindow("#poke", this.showHideChat.bind(this));
        // Bus.subscribe(
        //     CONNECTED,
        //     function () {
        //         Utils.addClass(this.indicatorSpinner, "hidden");
        //         Utils.addClass(this.callIndicator, "green");
        //     }.bind(this)
        // );
        // this.hangupEl.addEventListener("click", this.onHangupClick.bind(this));

        // this.photoContainer = this.container.querySelector(".photo-container");
        // this.userPhotoEl = this.photoContainer.querySelector(".user-photo");

        // this.showCaptureInfo = this.showCaptureInfo.bind(this);
      } else {
        this.container.replaceChildren();
      }
    }

    new Fullscreen(".fullscreen-new");

    // if (!this.minimalMode && !this.noLead && config.leadPage) {
    // this.phone = "";
    // this.email = "";

    // this.prepareLeadPage();
    // this.leadPageMaximize = document.getElementById("lead-page-maximize");
    // Utils.removeClass(this.leadPageMaximize, 'hidden');
    // this.leadPageShowed = false;
    // this.leadPageMaximize.addEventListener("click", () => {
    //     if (!this.leadPageShowed) {
    //         this.displayLeadPage();
    //     }
    // });
    // this.leadPageTimeout = setTimeout(() => {
    //     this.displayLeadPage();
    // }, leadPageConfig.showAfterIdleTime);
    // }

    //TODO: change to 15
    // this.connect();
    // this.selfPoke();
    // this.status = "initial";
    this.startStatsGather().then(({ stopGather, markAsAlive }) => {
      if (!stopGather) return;
      window.addEventListener("beforeunload", () => {
        stopGather();
      });
      if (!markAsAlive) return;
      markAsAlive();

      if (this.presentationId) return;

      const handleInactivity = () => {
        stopGather();
        const backdrop = document.createElement("div");
        backdrop.classList.add("backdrop");

        const messageContainer = document.createElement("div");
        messageContainer.classList.add("lobby-modal");
        messageContainer.style.flexDirection = "column";
        messageContainer.style.gap = "1em";

        const message = document.createElement("h3");
        message.innerText = i18n.t("txtYouHaveBeenInactiveForTooLong");
        messageContainer.appendChild(message);

        const refreshButton = document.createElement("button");
        refreshButton.classList.add("btn-primary");
        refreshButton.innerText = i18n.t("txtRefreshPage");
        refreshButton.addEventListener(
          "click",
          window.location.reload.bind(window.location)
        );
        messageContainer.appendChild(refreshButton);

        document.body.appendChild(backdrop);
        document.body.appendChild(messageContainer);
      };

      this.inactivityTimeout = setTimeout(handleInactivity, FIVE_MINUTES);

      window.addEventListener("mousemove", () => {
        if (this.isStatGatherStopped) return;
        if (this.inactivityTimeout) clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = setTimeout(handleInactivity, FIVE_MINUTES);
      });
    });
  }

  async onLobbyClose({ clientDto }) {
    await this.authenticateClient(clientDto);

    this.connect(async () => {
      try {
        const data = await this.requestToJoin(this.presentationId);
        const roomId = data?.roomId;

        if (!roomId) return;
        this.joinRoomById(roomId).then(({ roomId, history }) => {
          this.connecting = false;
          this.roomId = roomId;
          console.log("message history", history);
          Utils.addClass(this.userDataForm, "hide");
          Utils.removeClass(this.messageForm, "hide");
          this.loadChatHistory(history);
        });
      } catch (e) {
        this.presentationLobby.showError(e.message);
        setTimeout(() => {
          this.presentationLobby.closeLobby();
        }, 5 * 1000);
      }
    });
  }

  async getAgentAvailability() {
    const { isAgentAvailable } = await fetch(
      `${window.apiUrl}/api/forms/${this.folderId}/get-agent-availability`
    ).then((data) => data.json());
    return isAgentAvailable;
  }

  async authenticateClient({ name, phone }) {
    this.name = name;
    this.phone = phone ?? "000000000";
    const res = await fetch(`${window.hubsUrl}/client/authenticate`, {
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify({
        name: this.name,
        phoneNumber: this.phone,
        ...(this.deviceId && { deviceId: this.deviceId }),
      }),
    });

    const data = await res.json();
    this.deviceId = data?.device;
    localStorage.setItem("sb.deviceId", data?.device);

    this.signalR = data?.signalR;

    if (!res.ok) {
      console.warn("ERROR WHILE AUTHENTICATING CLIENT");
      throw new Error("ERROR WHILE AUTHENTICATING CLIENT");
    }

    return res.ok;
  }

  async startChat(e, nameArg, phoneArg) {
    Bus.post(CHAT_STARTED);
    this.isChatStarted = true;
    e.preventDefault();
    const name = nameArg || this.container.querySelector("#contact-name").value;
    const phone =
      phoneArg || this.container.querySelector("#contact-phone").value;
    this.name = name;
    this.phoneNumber = phone;

    await this.authenticateClient({ name, phone });

    this.connect(() =>
      this.joinRoomByClient(parseInt(this.folderId)).then(
        ({ roomId, history }) => {
          this.connecting = false;
          this.roomId = roomId;
          console.log("message history", history);
          Utils.addClass(this.userDataForm, "hide");
          this.userDataForm.reset();
          Utils.removeClass(this.messageForm, "hide");
          this.loadChatHistory(history);
        }
      )
    );
  }

  showCaptureInfo() {
    var captureInfo = document.querySelector(".captureInfo");
    Utils.removeClass(captureInfo, "hidden");
  }

  createCORSRequest(method, url, async) {
    var xhr = new XMLHttpRequest();
    if ("withCredentials" in xhr) {
      xhr.open(method, url, async == null ? true : async);
    } else if (typeof window.XDomainRequest != "undefined") {
      xhr = new window.XDomainRequest();
      xhr.open(method, url);
    } else {
      xhr = null;
    }
    return xhr;
  }

  prepareLeadPage() {
    // dodawanie tematow
    const url = window.hubsUrl + "/api/leadpage";

    this.leadPageContainer = document.getElementById("lead-page");
    this.leadPageContainer.outerHTML = leadPageTemplate;
    this.leadPageContainer = document.getElementById("lead-page");

    this.subject = this.leadPageContainer.querySelector(".lead-page-subject");

    var xhr = this.createCORSRequest("GET", url);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        const response = xhr.responseText;
        const data = JSON.parse(response);
        data.forEach((item) => {
          var subjectOptionNode = document.createElement("option");
          subjectOptionNode.value = item.id;
          subjectOptionNode.innerText = item["suggestedTitlePL"];
          this.subject.appendChild(subjectOptionNode);
        });
        this.subject.disabled = false;
      }
    };
    xhr.send();

    // pobieranie danych firmy
    var policyCheck = document.getElementById("leadPolicyCheck");
    policyCheck.addEventListener(
      "change",
      function (e) {
        this.leadPageSend.disabled = !e.target.checked;
      }.bind(this)
    );

    var policy = document.getElementById("leadPolicy");
    var xhr2 = this.createCORSRequest("GET", url + "/" + this.folderId);
    xhr2.onreadystatechange = () => {
      if (xhr2.readyState == 4 && xhr2.status == 200) {
        const response = xhr2.responseText;
        const data = JSON.parse(response);

        var policyText = window.ooc.t("txtLeadPolicy", {
          company: data.company,
          address: data.address,
          city: data.city,
          zip_code: data.zipCode,
        });

        if (!data.company || !data.address || !data.city || !data.zipCode) {
          console.warn("company data not found");
          clearTimeout(this.leadPageTimeout);
          this.leadPageShowed = true;
          this.leadPageMaximize.classList.add("hidden");
        }

        policy.innerText = policyText;
      }
    };
    xhr2.send();
  }

  displayLeadPage() {
    if (this.leadPageTimeout) {
      clearTimeout(this.leadPageTimeout);
    }
    this.leadPageShowed = true;

    this.leadPageContainer = document.getElementById("lead-page");
    Utils.removeClass(this.leadPageContainer, "hidden");

    this.leadPageSend = this.leadPageContainer.querySelector(".send");
    this.leadPageClose = this.leadPageContainer.querySelector(".close");
    this.leadPageMinimize = this.leadPageContainer.querySelector(".minimize");

    this.leadPageMaximize.addEventListener("click", () => {
      Utils.removeClass(this.leadPageContainer, "hidden");
      Utils.addClass(this.leadPageMaximize, "hidden");
    });

    this.leadPageMinimize.addEventListener("click", () => {
      Utils.addClass(this.leadPageContainer, "hidden");
      Utils.removeClass(this.leadPageMaximize, "hidden");
    });

    this.leadPageClose.addEventListener("click", () => {
      this.leadPageContainer.remove();
    });

    this.leadPageSend.addEventListener("click", () => {
      this.leadPageSend.disabled = true;
      Utils.addClass(this.leadPageSend, "disabled");
      this.sendLeadForm();
    });

    this.phone = this.leadPageContainer.querySelector(".lead-page-phone");
    this.email = this.leadPageContainer.querySelector(".lead-page-email");
    this.subject = this.leadPageContainer.querySelector(".lead-page-subject");

    this.phone.addEventListener("input", () => {
      this.formControlReset(this.phone);
      if (this.email.value.length < 1) {
        this.formControlReset(this.email);
      }
    });

    this.email.addEventListener("input", () => {
      this.formControlReset(this.email);
      if (this.phone.value.length < 1) {
        this.formControlReset(this.phone);
      }
    });

    this.email.addEventListener("change", () => {
      this.formControlReset(this.subject);
      if (this.subject.value) {
        this.formControlReset(this.subject);
      }
    });
  }
  formControlError(node, msg) {
    let formGroup = node.parentNode.parentNode;
    Utils.addClass(formGroup, " error");
    formGroup.querySelector(".details").textContent = msg;
  }
  formControlReset(node) {
    let formGroup = node.parentNode.parentNode;
    Utils.removeClass(formGroup, " error");
    formGroup.querySelector(".details").textContent = "";
  }
  validEmail(email) {
    return email.match(
      /^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i
    );
  }
  sendLeadForm() {
    setTimeout(() => {
      this.leadPageSend.disabled = false;
      Utils.removeClass(this.leadPageSend, "disabled");
    }, 500);

    let ok = true;
    let phone = this.leadPageContainer.querySelector(".lead-page-phone");
    let email = this.leadPageContainer.querySelector(".lead-page-email");
    let subject = this.leadPageContainer.querySelector(".lead-page-subject");

    if (!phone.value.length && !email.value.length) {
      this.formControlError(phone, "Wprowadź numer telefonu lub adres email");
      this.formControlError(email, "Wprowadź numer telefonu lub adres email");
      ok = false;
    }

    if (phone.value.length) {
      if (!phone.value.match(/\d/g)) {
        this.formControlError(phone, "Wprowadź poprawny numer");
        ok = false;
      } else {
        this.formControlReset(phone);
      }
    }

    if (email.value.length) {
      if (!this.validEmail(email.value)) {
        this.formControlError(email, "Wprowadź poprawny adres");
        ok = false;
      } else {
        this.formControlReset(email);
      }
    }

    if (!subject.value) {
      this.formControlError(subject, "Wybierz temat wiadomości");
      ok = false;
    }

    if (ok) {
      //this.invoke("LeadForm", this.phone.value, this.email.value, this.subject.value);

      const sendLeadUrl = `${window.hubsUrl}/api/leadpage?folderId=${this.folderId}&phone=${phone.value}&email=${email.value}&id=${subject.value}`;
      var sendLead = this.createCORSRequest("POST", sendLeadUrl);
      sendLead.send();
      sendLead.onreadystatechange = () => {
        if (sendLead.readyState == 4 && sendLead.status == 200) {
          this.leadThanks();
        }
      };
    }
  }
  leadThanks() {
    this.leadPageContainer.querySelector(".card-header-title").textContent =
      "Dziękujemy!";
    this.leadPageContainer.querySelector(".lead-page-form").remove();
    Utils.removeClass(
      this.leadPageContainer.querySelector(".lead-page-thanks"),
      "hidden"
    );
    Utils.addClass(this.leadPageSend, "hidden");
    Utils.removeClass(this.leadPageClose, "hidden");
    this.leadPageMinimize.remove();
    this.leadPageMaximize.remove();
  }

  loadChatHistory(history) {
    // this.messageList.innerHTML = "";
    this.addSeparator();
    if (!history || !history?.length) {
      // const noHistory = document.createElement("li");
      // noHistory.innerText = "Brak wiadomości";
      this.addMessage({
        message: `${i18n.t("txtWelcome")} ${this.name}. ${i18n.t(
          "txtUserCta"
        )}`,
        sent: new Date().toISOString(),
      });
    } else {
      history.forEach((message, index) => {
        this.addMessage(message, history?.[index - 1]?.sent);
      });
    }
  }

  connect(clb) {
    if (this.connecting) {
      return;
    }

    this.connecting = true;
    this.connection = new HubConnectionBuilder()
      .withUrl(
        window.hubsUrl +
          `/chat?signalr-auth-cookie=${this.signalR}&device_cookie=${this.deviceId}`
      )
      .build();

    this.connection.on("ReceiveMessage", this.onMessage.bind(this));
    this.connection.on(
      "RequestToJoinPresentationAccepted",
      this.onRequestToJoinAccepted.bind(this)
    );
    this.connection.on(
      "RequestToJoinPresentationDeclined",
      this.onRequestToJoinPresentationDeclined.bind(this)
    );

    this.connection.on("ViewChanged", (data) => {
      if (!this.linker) return;

      this.linker.set(data);
    });

    this.connection.on("SceneChanged", (data) => {
      if (!this.linker) return;

      this.linker.requestChangeScene({
        photoId: data.sceneId,
      });
    });

    this.connection.on("ControlRevoked", () => {
      if (!this.presentation) return;

      this.onMessage({
        message: i18n.t("txtControlRevoked"),
        sent: new Date().toISOString(),
        sentByClient: true,
        userName: this.config?.UserName,
      });

      this.presentation.toggleControl(false);
    });

    this.connection.on("ControlGained", () => {
      if (!this.presentation) return;

      this.onMessage({
        message: i18n.t("txtControlGained"),
        sent: new Date().toISOString(),
        sentByClient: true,
        userName: this.config?.UserName,
      });

      this.presentation.toggleControl(true);
    });

    this.connection.on("PresentationFinished", () => {
      if (!this.presentation) return;

      this.onMessage({
        message: i18n.t("txtPresentationEnded"),
        sent: new Date().toISOString(),
        sentByClient: true,
        userName: this.config?.UserName,
      });

      this.presentation.endCall();
      this.connection.off("ViewChanged");
      this.connection.off("SceneChanged");
      this.connection.off("ControlRevoked");
      this.connection.off("ControlGained");
    });

    if (this.connection.state === "Connected") return;
    this.connection.start().then(clb);
  }

  async joinRoomByClient(folderId) {
    return this.connection.invoke("JoinRoomByClient", { folderId });
  }

  async joinRoomById(roomId) {
    return this.connection.invoke("JoinRoomByIdAsClient", { roomId });
  }

  async requestToJoin(presentationId) {
    return this.connection.invoke("RequestToJoinPresentation", {
      presentationId,
    });
  }

  startConverstation() {
    var name = window.ooc.t("txtDefaultName");
    this.invoke("StartChat", name);

    clearTimeout(this.leadPageTimeout);
  }

  reconnect(clb) {
    if (this.reconnecting) {
      return;
    }
    try {
      this.reconnecting = true;
      this.reconnectRefresh = true;
      this.connect(clb);
    } finally {
      this.reconnecting = false;
    }
  }
  invoke(command) {
    //arguments ommited on purpose
    if (this.connection && this.connection.connection.connectionState !== 1) {
      //not connected state
      this.onDisconnected({
        name: "System",
        timeStamp: new Date().toISOString(),
        message: window.ooc.t("txtChatInterrupted"),
      });
    } else {
      if (this.connection && this.connection.invoke) {
        this.connection.invoke.apply(this.connection, arguments);
      }
    }
  }
  invokeForce(command) {
    //arguments ommited on purpose
    if (this.connection && this.connection.connection.connectionState !== 1) {
      //not connected state
      this.onDisconnected({
        name: "System",
        timeStamp: new Date().toISOString(),
        message: window.ooc.t("txtChatInterrupted"),
      });
    } else {
      this.connection.invoke.apply(this.connection, arguments);
    }
  }

  onRequestToJoinAccepted(payload) {
    if (this.presentation) return;

    this.presentationLobby.closeLobby();

    this.presentation = new VirtualPresentation(
      payload,
      this.presentationLobby.getTracks(),
      this.connection,
      this.roomId,
      ".presentation-participants"
    );
  }

  onRequestToJoinPresentationDeclined() {
    if (this.presentation) return;

    this.presentationLobby.showError(
      `${i18n.t("txtAgentDeclinedYourJoinRequest")}. ${i18n.t(
        "txtThisWindowWillCloseSoon"
      )}.`
    );
    setTimeout(() => {
      this.presentationLobby.closeLobby();
    }, 5 * 1000);
  }

  onMessage(payload, delayMessage = true) {
    if (
      delayMessage &&
      (dayjs().diff(dayjs(this.lastMessageDate), "s") < 3 ||
        this.messagesInQueue)
    ) {
      const diff = (
        this.messagesInQueue
          ? this.lastMessageInQueueDate
          : this.lastMessageDate
      )
        .add(3, "s")
        .diff(dayjs());
      setTimeout(() => {
        if (
          !this.isChatStarted ||
          (this.isChatStarted && !payload.isSystemMessage)
        ) {
          this.onMessage2(payload);
        }
      }, diff);
      this.messagesInQueue = this.messagesInQueue + 1;
      this.lastMessageInQueueDate = dayjs().add(diff, "ms");
    } else {
      this.onMessage2(payload);
    }
  }

  onMessage2(payload, dataAgent) {
    if (this.timeToRemoveNewMessage) {
      clearTimeout(this.timeToRemoveNewMessage);
    }
    this.timeToRemoveNewMessage = setTimeout(() => {
      const newMessageList = this.container.querySelector("#new-message-list");
      if (newMessageList.childElementCount > 1) {
        newMessageList.removeChild(newMessageList.firstElementChild);
      }
    }, 5000);
    this.lastMessageDate = dayjs();
    if (this.messagesInQueue > 0) {
      this.messagesInQueue = this.messagesInQueue - 1;
    }
    Bus.post(NEW_MESSAGE);
    if (payload) {
      if (
        Utils.checkClass(this.chatContainer, "chat2__chat-container--hidden")
      ) {
        this.unreadMessageCount++;
        this.unreadMessageCounter.innerText = this.unreadMessageCount;
        Utils.removeClass(this.unreadMessageCounter, "hidden");
        let message = typeof payload === "string" ? payload : payload?.message;
        if (message.startsWith("txt")) {
          message = i18n.t(message);
        }
        if (message) {
          const messageContent = document.createElement("div");
          Utils.addClass(messageContent, "chat2__new-message-content");
          if (payload?.onClick) {
            messageContent.addEventListener("click", payload?.onClick);
            Utils.addClass(
              messageContent,
              "chat2__new-message-content--clickable"
            );
          }

          if (payload?.withQuestion) {
            this.addActionButtonsToMessage(messageContent, payload);
          } else if (payload?.withContactForm) {
            this.addContactFormToMessage(messageContent, payload);
          } else {
            messageContent.innerText = message;
          }

          const newMessage = document.createElement("div");
          Utils.addClass(newMessage, "chat2__new-message-container");
          const messageId = payload?.sent
            ? new Date(payload.sent).getTime()
            : Math.random().toString().slice(2, 10);
          newMessage.dataset.id = messageId;
          newMessage.textContent = "";
          newMessage.appendChild(messageContent);
          if (this.newMessageList.childElementCount > 1) {
            this.newMessageList.removeChild(this.newMessageList.firstChild);
          }
          this.newMessageList.appendChild(newMessage);
          const wordCount = message.split(" ").length;

          if (!payload?.disableAutoHiding) {
            this.newMessageTiemout = setTimeout(() => {
              const messageToRemove = this.newMessageList.querySelector(
                `.chat2__new-message-container[data-id="${messageId}"]`
              );
              if (messageToRemove) {
                this.newMessageList.removeChild(messageToRemove);
              }
            }, payload?.hideAfter ?? 2500 + wordCount * 300);
          }
        }
      }
      this.addMessage(payload);
    }
  }
  onDisconnected(payload) {
    this.live.stop();
    this.onHangUp();
    this.onMessage(payload);
    // Utils.addClass(this.sendWrapper, " hidden");
    // Utils.removeClass(this.reconnectWrapper, " hidden");
    Utils.addClass(this.rtcActions, " hidden");
    Utils.addClass(this.sendWrapper, " hidden");
    this.refreshWrapper.querySelector(".refresh-text").textContent =
      window.ooc.t("txtRefresh");
    Utils.removeClass(this.refreshWrapper, " hidden");
  }
  onMessageKeyPressed(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      this.sendMessage();
    } else {
      this.onMessageInput();
    }
  }
  onMessageInput() {
    this.setInputHeight();
  }
  onMessageClicked(e) {
    e.preventDefault();
    this.sendMessage();
  }

  setInputHeight() {
    this.message.style.height = "auto";
    let height = this.message.scrollHeight + 6;
    this.message.style.height = height + "px";
    height += 90;
    this.conversation.style.maxHeight = "calc(280px + 200px - " + height + "px";
  }

  onSetCookie(key, value) {
    const d = new Date();
    d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = key + "=" + value + ";" + expires;
  }
  onError(value) {
    this.addMessage({
      message: window.ooc.t("txtCommunicationError"),
      timeStamp: new Date().toISOString(),
      direction: "in",
      error: true,
    });
  }
  capture(value, options) {
    console.log("capture 1");
    this.onTrasmittingChanged(value, this.transmitting);
    this.transmitting = value;
    if (this.transmitting) {
      this.receiving = false;
    }
    if ((options && options.transfer !== false) || !options) {
      console.log("capture 2");
      this.invoke("Capture", value);
    }
  }
  selfPoke() {
    setTimeout(
      function () {
        this.invoke("SelfPoke");
      }.bind(this),
      5000
    );
  }
  onCapture(value) {
    console.log("on capture");
    this.receiving = value;
    if (this.receiving) {
      console.log("on capture reciving");

      this.showCaptureInfo();
      Utils.removeClass(this.callEl, "hidden");

      this.onTrasmittingChanged(false, this.transmitting);
      this.transmitting = false;
    }
  }
  onReceiveCapturedData(payload) {
    if (this.receiving) {
      if (this.store.currentSceneId != payload.scene) {
        this.linker.requestChangeScene({
          photoId: payload.scene,
          targetId: payload.target,
        });
      }
      this.linker.set(payload);
    }
  }
  sendCaptureData(data) {
    if (this.transmitting) {
      this.invoke(
        "SendCapturedData",
        data.pitch,
        data.yaw,
        data.fov,
        data.scene,
        data.target
      );
    }
  }
  changeScene(data) {
    if (this.transmitting) {
      if (this.store.photosCount != 1) {
        this.invoke("ChangeScene", data.photoId, data.targetId);
      }
      var data = this.linker.get();
      //it was causing multiple scene switching
      //this.sendCaptureData(data);
    }
  }
  onChangeScene(payload) {
    if (this.receiving && this.store.currentSceneId != payload.photoId) {
      this.linker.requestChangeScene(payload);
    }
  }
  sendMessage() {
    const value = this.message.value.trim();
    if (!value || value.length > msgMaxLength) {
      console.log("message length error");
      Utils.removeClass(this.conversationError, " hidden");
      this.conversationError.innerText =
        window.ooc.t("txtMaxMessageLength") +
        msgMaxLength +
        window.ooc.t("txtChars");
    } else if (!this.connection || this.connection.state !== "Connected") {
      console.log("connection error");
    } else {
      this.connection.invoke("SendMessage", {
        roomId: this.roomId,
        message: value,
      });
      this.message.value = "";
      this.addMessage({
        message: value,
        sent: new Date().toISOString(),
        sentByClient: true,
        userName: this.name,
        roomId: this.roomId,
      });
    }
  }

  addDateMessage(date) {
    const separator = document.createElement("li");
    Utils.addClass(separator, "chat-container__message--title");
    separator.innerText = dayjs(date).format("D MMMM YYYY r.");
    this.messageList.appendChild(separator);
  }

  addSeparator() {
    const separator = document.createElement("li");
    Utils.addClass(separator, "chat-container__message--separator");
    this.messageList.appendChild(separator);
  }

  addActionButtonsToMessage(element, payload) {
    if (payload?.withQuestion) {
      const question = document.createElement("div");
      Utils.addClass(question, "message-content");
      question.innerHTML = payload?.message?.replaceAll(
        linkRegex,
        (match) =>
          `<a href="${match}" target="_blank" rel="noopener">${match}</a>`
      );
      const messageId = new Date(payload?.sent).getTime();
      element.appendChild(question);
      const buttonPositive = document.createElement("button");
      buttonPositive.classList.add("chat-container__message-button--positive");
      buttonPositive.innerText =
        payload?.positiveButtonText ||
        i18n.t("txtYes", { lng: this.creationLanguage });
      if (
        payload?.positiveButtonAction &&
        typeof payload.positiveButtonAction === "function"
      ) {
        buttonPositive.addEventListener("click", () => {
          const messagesFromId = this.container.querySelectorAll(
            `*[data-id="${messageId}"]`
          );
          messagesFromId.forEach((message) => {
            const positiveButtonInMessage = message.querySelector(
              ".chat-container__message-button--positive"
            );
            const negativeButtonInMessage = message.querySelector(
              ".chat-container__message-button--negative"
            );
            positiveButtonInMessage.classList.add(
              "chat-container__message-button--selected"
            );
            positiveButtonInMessage.disabled = true;
            negativeButtonInMessage.disabled = true;
          });
          payload.positiveButtonAction();
        });
      }
      const buttonNegative = document.createElement("button");
      buttonNegative.classList.add("chat-container__message-button--negative");
      buttonNegative.innerText =
        payload?.negativeButtonText ||
        i18n.t("txtYes", { lng: this.creationLanguage });
      if (
        payload?.negativeButtonAction &&
        typeof payload.negativeButtonAction === "function"
      ) {
        buttonNegative.addEventListener("click", () => {
          const messagesFromId = this.container.querySelectorAll(
            `*[data-id="${messageId}"]`
          );
          messagesFromId.forEach((message) => {
            const positiveButtonInMessage = message.querySelector(
              ".chat-container__message-button--positive"
            );
            const negativeButtonInMessage = message.querySelector(
              ".chat-container__message-button--negative"
            );
            negativeButtonInMessage.classList.add(
              "chat-container__message-button--selected"
            );
            negativeButtonInMessage.disabled = true;
            positiveButtonInMessage.disabled = true;
          });
          payload.negativeButtonAction();
        });
      }
      const buttonContainer = document.createElement("div");
      buttonContainer.classList.add("chat-container__message-buttons");
      buttonContainer.appendChild(buttonPositive);
      buttonContainer.appendChild(buttonNegative);
      element.appendChild(buttonContainer);
    }
  }

  async sendNotificationToAgent(phoneNumber) {
    await fetch(`${window.apiUrl}/api/forms/${this.folderId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumber,
      }),
    });
  }

  async addContactFormToMessage(element, payload) {
    if (payload?.withContactForm) {
      const isAgentAvailable = await this.getAgentAvailability();
      const uniqueId = `ct-label-${Math.random().toString().slice(2, 10)}`;
      const container = document.createElement("div");
      Utils.addClass(container, "message-content");
      container.innerHTML = payload?.message?.replaceAll(
        linkRegex,
        (match) =>
          `<a href="${match}" target="_blank" rel="noopener">${match}</a>`
      );
      const messageId = new Date(payload?.sent).getTime();
      const form = document.createElement("form");
      form.classList.add("chat-container__message-contact-form");
      const inputWrapper = document.createElement("div");
      inputWrapper.classList.add("chat-container__message-input-wrapper");
      const phoneInput = document.createElement("input");
      phoneInput.classList.add("chat-container__message-input");
      phoneInput.type = "phone";
      phoneInput.placeholder = i18n.t("txtYourPhoneNumber", {
        lng: this.creationLanguage,
      });
      const submitButton = document.createElement("button");
      submitButton.classList.add("chat-container__message-form-submit");
      submitButton.innerText = i18n.t(
        isAgentAvailable ? "textStartConversationShort" : "txtSend",
        {
          lng: this.creationLanguage,
        }
      );
      const checkboxWrapper = document.createElement("div");
      checkboxWrapper.classList.add("chat-container__checkbox-wrapper");
      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.id = uniqueId;
      checkbox.required = true;
      const checkboxLabel = document.createElement("label");
      checkboxLabel.innerText = i18n.t("txtMessageContactFormAcceptRulesText", {
        lng: this.creationLanguage,
      });
      checkboxLabel.style.fontSize = "10px";
      checkboxLabel.htmlFor = uniqueId;
      checkboxWrapper.appendChild(checkbox);
      checkboxWrapper.appendChild(checkboxLabel);
      inputWrapper.appendChild(phoneInput);
      inputWrapper.appendChild(submitButton);
      form.appendChild(inputWrapper);
      form.appendChild(checkboxWrapper);
      container.appendChild(form);
      element.appendChild(container);
      form.addEventListener("submit", (e) => {
        e.preventDefault();
        if (isAgentAvailable) {
          if (
            Utils.checkClass(
              this.chatContainer,
              "chat2__chat-container--hidden"
            )
          ) {
            Utils.removeClass(
              this.chatContainer,
              "chat2__chat-container--hidden"
            );
            this.unreadMessageCount = 0;
            Utils.addClass(this.unreadMessageCounter, "hidden");
            this.newMessageList.textContent = "";
          }
          this.startChat(e, phoneInput.value, phoneInput.value);
        } else {
          this.sendNotificationToAgent(phoneInput.value);
          const messagesFromId = this.container.querySelectorAll(
            `*[data-id="${messageId}"] .message-content`
          );
          messagesFromId.forEach((container) => {
            const formInContainer = container.querySelector("form");
            container.removeChild(formInContainer);
          });
          this.onMessage(
            {
              message: i18n.t("txtContactRequestSent"),
              sent: new Date().toISOString(),
              sentByClient: true,
              userName: this.config?.UserName,
            },
            false
          );
        }
      });
    }
  }

  addMessage(payload, lastMessageDate) {
    const messageElement = document.createElement("li");
    const messageId = payload?.sent
      ? new Date(payload.sent).getTime()
      : Math.random().toString().slice(2, 10);
    messageElement.dataset.id = messageId;
    if (payload?.onClick) {
      messageElement.addEventListener("click", payload.onClick);
      Utils.addClass(messageElement, "chat-container__message--clickable");
    }
    const date = dayjs(payload?.sent);
    if (lastMessageDate || this.messageList.lastChild) {
      const previousDate = dayjs(
        lastMessageDate
          ? lastMessageDate
          : this.messageList.lastChild?.dataset?.sent
      );
      const diffDays = date.startOf("day").diff(previousDate, "day");
      if (diffDays > 0) {
        this.addDateMessage(date);
      }
    } else {
      this.addDateMessage(date);
    }

    if (typeof payload === "string" && !this.agentJoinedBefore) {
      Utils.addClass(messageElement, "chat-container__message--title");
      messageElement.innerText = payload;
      this.messageList.appendChild(messageElement);
      this.agentJoinedBefore = true;
    } else if (Utils.isObject(payload) && payload?.message) {
      Utils.addClass(
        messageElement,
        `chat-container__message--${
          payload?.type === "tip"
            ? "tip"
            : payload.userName === this.name
            ? "right"
            : "left"
        }`
      );
      if (payload?.withQuestion) {
        this.addActionButtonsToMessage(messageElement, payload);
      } else if (payload?.withContactForm) {
        this.addContactFormToMessage(messageElement, payload);
      } else {
        messageElement.innerHTML = payload?.message?.replaceAll(
          linkRegex,
          (match) =>
            `<a href="${match}" target="_blank" rel="noopener">${match}</a>`
        );
      }
      messageElement.title = dayjs(payload.sent).format("HH:mm:ss");
      messageElement.dataset.sent = payload.sent;
      this.messageList.appendChild(messageElement);
      const conversationContainer = this.container.querySelector(
        "#conversation-container"
      );
      conversationContainer.scrollTop = conversationContainer.scrollHeight;
    } else {
      console.warn("New message in unknown format:", payload);
    }
    // if (payload) {
    //     const element = document.createElement("div");
    //     Utils.addClass(element, "message-container");
    //     Utils.addClass(element, payload.direction);

    //     const entryWr = document.createElement("div");
    //     Utils.addClass(entryWr, "entry-wrapper");
    //     if (payload.direction == "in") {
    //     } else {
    //     }
    //     const messageWr = document.createElement("div");
    //     Utils.addClass(messageWr, "message-wrapper");
    //     const message = document.createElement("div");
    //     Utils.addClass(message, "msg");
    //     if (payload.error) {
    //         Utils.addClass(message, "error");
    //     }
    //     var matches = this.parseLinks(payload.message);
    //     for (const match of matches) {
    //         if (match.isLink) {
    //             const link = document.createElement("a");
    //             link.href = match.value;
    //             link.target = "_blank";
    //             link.innerText = match.value;
    //             message.appendChild(link);
    //         } else {
    //             const el = document.createElement("span");
    //             el.innerText = match.value;
    //             message.appendChild(el);
    //         }
    //     }
    //     messageWr.appendChild(message);
    //     if (payload.direction == "in") {
    //         entryWr.appendChild(messageWr);
    //     } else {
    //         entryWr.appendChild(messageWr);
    //     }
    //     element.appendChild(entryWr);

    //     const timeWr = document.createElement("div");
    //     Utils.addClass(timeWr, "time-wrapper");
    //     const timeEl = document.createElement("div");
    //     Utils.addClass(timeEl, "time");
    //     const time = new Date(payload.timeStamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    //     timeEl.innerText = time;
    //     timeWr.appendChild(timeEl);
    //     element.appendChild(timeWr);

    //     this.conversation.appendChild(element);
    //     this.scrollToEnd();
    // }
  }

  parseLinks(msg) {
    let result = [];
    let cursor = 0;
    const matches =
      msg.match(
        /(https?:\/\/)?(www\.)?[-\w@:%.\+~#=]{2,256}\.[a-z]{2,6}\b([\w-@:%\+.~#?&//=]*)/g
      ) || [];
    for (const match of matches) {
      const index = msg.indexOf(match, cursor);
      result.push({ isLink: false, value: msg.substring(cursor, index) });
      let link = msg.substr(index, match.length);
      link = link.startsWith("http") ? link : "http://" + link;
      result.push({ isLink: true, value: link });
      cursor = index + match.length;
    }
    result.push({ isLink: false, value: msg.substring(cursor, msg.length) });
    return result;
  }
  getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  onChatBtnClick() {
    this.onChatBtnClickCallback();
  }

  setChatBtnClickCallback(clb) {
    this.onChatBtnClickCallback = clb;
  }

  showHideChat(order) {
    if (!order || (order != "show" && order != "hide")) {
      order = this.isChatHidden() ? "show" : "hide";
    }
    if (this.status === "initial") {
      order = "show";
      this.startConverstation();
      Utils.addClass(this.maximizelabel, "hidden");
    }
    switch (order) {
      case "show":
        Utils.removeClass(this.container, "hide");
        Utils.addClass(this.container, "show");
        Utils.addClass(this.indicator, "hidden");
        this.container.style.maxHeight = "460px";
        Utils.addClass(this.maximize, "hidden");
        Utils.removeClass(this.minimize, "hidden");
        this.status = "visible";
        break;
      case "hide":
        Utils.removeClass(this.container, "show");
        Utils.addClass(this.container, "hide");
        Utils.addClass(this.container, "no-trans");
        Utils.removeClass(this.maximize, "hidden");
        Utils.addClass(this.minimize, "hidden");
        this.container.style.maxHeight = this.container.offsetHeight + "px";
        // this.container.offsetHeight = ; // trigger a reflow, flushing the CSS changes
        Utils.removeClass(this.container, "no-trans");
        this.container.style.maxHeight = minChatHeight;
        this.status = "hidden";
        break;
    }
  }
  isChatHidden() {
    return this.status === "hidden";
  }
  scrollToEnd() {
    const scrollableDiv = this.container.querySelector(".conversation");
    scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
  }
  onRefreshClick() {
    this.reconnect(() => {
      var name = window.ooc.t("txtDefaultName");
      this.invokeForce("StartChat", name);
    });
  }
  onLinkClick() {
    this.capture(true);
    this.changeScene({
      photoId: this.store.currentSceneId,
      targetId: this.store.recentTargetId,
    });
  }
  onUnlinkClick() {
    this.capture(false);
  }
  onTrasmittingChanged(value, old) {
    if (old && !value) {
      Utils.addClass(this.unlinkEl, " hidden");
      Utils.removeClass(this.linkEl, " hidden");
    } else if (!old && value) {
      Utils.addClass(this.linkEl, " hidden");
      Utils.removeClass(this.unlinkEl, " hidden");
    }
  }
  onCallClick() {
    Utils.addClass(this.callEl, " hidden");
    this.invoke("Call", true);
    Utils.removeClass(this.indicatorSpinner, "hidden");
    Utils.removeClass(this.hangupEl, " hidden");
  }
  onHangupClick() {
    this.live.stop();
    this.onHangUp();
    this.invoke("RejectCall");
  }
  onHangUp() {
    Utils.addClass(this.hangupEl, " hidden");
    Utils.removeClass(this.callIndicator, "green");
    Utils.removeClass(this.callEl, " hidden");
  }
  onRejectCall() {
    this.onHangUp();
  }
  onWelcome(payload, userPhotoUrl, dataAgent) {
    this.showHideChat("show");
    clearTimeout(this.waitingTimer);
    Utils.removeClass(this.rtcActions, " hidden");
    this.showPhoto(userPhotoUrl);
    this.onMessage(payload, dataAgent);
  }

  showPhoto(userPhotoUrl) {
    Utils.removeClass(this.photoContainer, "hidden");
    this.userPhotoEl.src = userPhotoUrl;
  }

  onDisableFeature(feature, message) {
    this.onMessage(message);
    if (feature == "VoiceCalls") {
      this.live.stop();
      this.onHangUp();
    } else if (feature == "Presentations") {
      this.capture(false, { transfer: false });
    }
  }

  onBanned(message) {
    this.onMessage(message);
    this.connection.stop();
  }

  onWaiting(message, unavailable) {
    this.unavailable = unavailable;
    this.onMessage(message);
    this.waitingTimer = setTimeout(this.afterWaiting.bind(this), 60 * 1000);
  }

  onPoke(payload, photoUrl, dataAgent) {
    this.showPhoto(photoUrl);
    this.pokeWindow.show(payload.message, payload.name, photoUrl, dataAgent);
  }

  onSellerBusy(busyMessage) {
    var message = {
      name: "System",
      message: busyMessage,
      timeStamp: new Date().toISOString(),
    };
    this.onMessage(message);
    clearTimeout(this.waitingTimer);
  }

  onSellerBusyClosed() {
    Utils.addClass(this.rtcActions, " hidden");
    Utils.addClass(this.sendWrapper, " hidden");

    this.refreshWrapper.querySelector(".refresh-text").textContent =
      window.ooc.t("txtTryAgain");
    Utils.removeClass(this.refreshWrapper, " hidden");

    if (!this.leadPageShowed) {
      this.leadPageTimeout = setTimeout(() => {
        this.displayLeadPage();
      }, leadPageConfig.showAfterBusyTime);
    }
  }

  afterWaiting() {
    this.invoke("Deactivated");
    var message = {
      name: "System",
      message: this.unavailable,
      timeStamp: new Date().toISOString(),
    };
    this.onMessage(message);
  }

  hasFeature(folderId) {
    const url = window.hubsUrl + "/hasChat/" + folderId;
    const xhr = this.createCORSRequest("GET", url, false);
    xhr.send();
    if (xhr.readyState === 4 && xhr.status === 200) {
      const response = xhr.responseText;
      return JSON.parse(response);
    }
  }

  async startStatsGather() {
    const params = new URLSearchParams(window.location.search);
    const portal = params.has("portal") ? params.get("portal") : "unknown";

    try {
      const res = await fetch(
        `${window.hubsUrl}/statistics/start-visit?FolderId=${this.projectId}&Portal=${portal}`,
        {
          method: "POST",
        }
      );
      if (!res.ok) throw new Error(res.status);

      const token = await res.json();
      const intervals = [1, 3, 7, 15, 31];
      const markAsAlive = (intervalIndex) => {
        if (this.isStatGatherStopped) return;
        let index = intervalIndex || 0;
        if (intervals.length <= index) {
          index = intervals.length - 1;
        }
        this.markAsAliveTimeout = setTimeout(() => {
          fetch(
            `${window.hubsUrl}/statistics/continue-visit?FolderId=${this.projectId}&Token=${token}`,
            {
              method: "PATCH",
            }
          ).finally(() => markAsAlive(index + 1));
        }, intervals[index] * 1000);
      };
      return {
        stopGather: () => {
          clearTimeout(this.markAsAliveTimeout);
          fetch(
            `${window.hubsUrl}/statistics/stop-visit?FolderId=${this.projectId}&Token=${token}`,
            {
              method: "PATCH",
            }
          ).then(() => {
            this.isStatGatherStopped = true;
          });
        },
        markAsAlive,
      };
    } catch (error) {
      console.warn("FAILED STAT GATHER START");
      return false;
    }
  }
}

export default Chat;
