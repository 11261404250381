import Utils from "./utils";

export class Speech {
    constructor(config) {
        this.muted = false;
        this.container = document.querySelector(".chat2")
        this.tracks = config?.VirtualGuideMessages?.Photos?.reduce((acc, photo) => {
            return photo?.Messages
                .filter(message => message?.SpeechUrl != null)
                .reduce((acc, message) => {
                const audioElement = document.createElement("audio");
                audioElement.id = `vgm-${message?.Id}`
                audioElement.src = message?.SpeechUrl;
                this.container.appendChild(audioElement);
                acc[photo?.PhotoId] = audioElement;
                return acc;
            }, acc);
        }, {});
        this.muteButton = document.querySelector("#mute-btn");
        this.updateIcon();
        Utils.registerEvent(this.muteButton,"click", (e) => {
            e.stopPropagation();
            this.muted = !this.muted;
            this.updateIcon();
            for (let photoId in this.tracks) {
                const track = this.tracks[photoId];
                track.volume = this.muted ? 0 : 1;
            }
        })
    }
    
    play(photoId) {
        const track = this.tracks[photoId];
        track.volume = this.muted ? 0 : 1;
        track.play();
        return (track.duration === 0 ? 10 : track.duration) * 1000;
    }
    
    stop() {
        for (let photoId in this.tracks) {
            const track = this.tracks[photoId];
            track.pause();
            track.currentTime = 0;
        }
    }
    
    updateIcon() {
        this.mutedElement = this.muteButton.querySelector("#muted");
        this.unmutedElement = this.muteButton.querySelector("#unmuted");
        Utils.setVisibility(this.mutedElement, this.muted === true);
        Utils.setVisibility(this.unmutedElement, this.muted === false)
    }
}

export class PlayList {
    constructor() {
        this.alreadyPlayed = [];
        this.queue = [];
        this.nextUpdate = null;
        this.current = null;
    }
    addToQueue(item) {
        this.queue.push(item)
        this.tryNext();
        
    }

    tryNext() {
        if(this.current == null) {
            if(this.queue.length > 0) {
                this.current = this.queue[0];
                this.queue.splice(0, 1)
                if(this.alreadyPlayed.indexOf(this.current.id) >= 0) {
                    setTimeout(this.finished.bind(this), 0);//avoid stack overflow
                    return;
                }
                this.alreadyPlayed.push(this.current.id);
                const duration = this.current.action(this.current.id);
                this.nextUpdate = setTimeout(this.finished.bind(this), duration);
            }
        }
    }
    
    finished() {
        this.current = null;
        this.tryNext();
    }

    clearQueue() {
        clearTimeout(this.nextUpdate);
        this.queue.length = 0;
        this.current = null;
    }
}