import i18n from "../config/i18n";
import Utils from "./utils";

class PokeWindow {
    constructor(selector, openChat) {
        this.container = document.querySelector(selector);
        this.content = this.container.querySelector(".content");
        this.headerTitle = this.container.querySelector(".header .title");
        this.headerTitle.innerText = i18n.t("txtPokeTitle");
        this.closeButton = this.container.querySelector(".close");
        this.closeButton.addEventListener("click", this.close.bind(this));
        this.yes = this.container.querySelector(".actions .yes");
        this.yes.addEventListener("click", () => {
            openChat();
            this.close();
        });
        this.no = this.container.querySelector(".actions .no");
        this.no.addEventListener("click", this.close.bind(this));
    }

    show(message, name, photoUrl, dataAgent) {
        this.content.innerText = message;
        Utils.removeClass(this.container, "hidden");
    }

    close() {
        Utils.addClass(this.container, "hidden");
    }
}

export default PokeWindow;
