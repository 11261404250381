import Marzipano from "marzipano";
import viewportTemplate from "App/pages/viewport.html";
import hotspotDoorTemplate from "App/pages/hotspotDoor.html";
import hotspotDoorOnGroundTemplate from "App/pages/hotspotDoorOnGround.html";
import hotspotInfoTemplate from "App/pages/hotspotInfo.html";
import hotspotLogoTemplate from "App/pages/hotspotLogo.html";
import Intro from "App/js/intro.js";
import Utils from "App/js/utils.js";
import { Bus } from "App/js/simple-bus.js";
import { SCENE_CHANGED } from "App/js/events.js";
import Easing from "App/js/easing";
import Floorplan from "App/js/floorplan";
import icons from "App/js/icons.js";
import { text } from "@fortawesome/fontawesome";
import i18n from "../config/i18n";
import ActivityTracker from "./activity-tracker";
import {
  INTRO_CLOSED,
  MEDIA_PREVIEW_CLOSED,
  MEDIA_PREVIEW_OPENED,
  VIEW_CHANGED,
} from "./events";

const POINT_DOOR = 0;
const POINT_INFO = 1;
const POINT_LOGO = 2;

const AUTOROTATE_ACTIVE = true;
const AUTOROTATE_CHANGE_SCENE_TIME = 10;
const AUTOROTATE_CHANGE_SCENE_DELAY = 5000;
const AUTOROTATE_MOVEMENT = Marzipano.autorotate({
  yawAccel: 8,
  yawSpeed: 0.05, // Yaw rotation speed
  targetPitch: 0.2, // Pitch value to converge to
  targetFov: Math.PI / 2, // Fov value to converge to
});

export default class VirtualWalk {
  constructor(project, store, options, config) {
    const { minimalMode, autorotate, showNadir } = options;

    const containerCandidates = document.getElementsByTagName("body");

    if (!containerCandidates.length) {
      return;
    }
    this.container = containerCandidates[0];
    if (!project) {
      console.warn("Missing project");
      return;
    }

    this.autorotate = autorotate;
    this.minimalMode = minimalMode;
    this.showNadir = showNadir;

    this.config = config;
    const projectSplit = project.split("-");
    this.projectId = Number(projectSplit[projectSplit.length - 1]);

    this.createViewer();
    this.intro = new Intro(this.container, minimalMode, config);
    this.store = store;
    this.switching = false;

    this.bigpic = document.getElementById("bigpic");
    Utils.registerEvent(
      this.bigpic,
      "click",
      function () {
        this.hideBigPic();
      }.bind(this)
    );

    this.setupNavigator();
  }

  createFloorplam() {
    this.floorplan = new Floorplan(this.container, this.project.Photos);
    this.floorplan.addEventListener(
      "onPointClick",
      function (id) {
        const photo = this.project.map[id];
        if (photo) {
          this.switchSceneDirect(photo);
        }
      }.bind(this)
    );
  }

  load(ready) {
    this.hideSpinner();
    if (Object.prototype.toString.call(this.config) !== "[object Object]") {
      if (this.config === 404) {
        this.intro.showLoadFailed();
      } else {
        this.intro.showLoadFailed(true);
      }
    } else {
      this.buildMap(this.config);
      if (this.config.Status == 2) {
        //2 - published
        //const startScene = this.getStartScene();
        let first;
        if (this.config.StartingPhotoId != null) {
          first = this.getPhoto(this.config.StartingPhotoId);
        } else {
          first = this.getFirst();
        }

        if (first == null) {
          this.hideSpinner();
          this.showMessage(i18n.t("txtNoPhotosInWalk"));
          return;
        }

        if (this.config.IsFloorplan) {
          this.createFloorplam();
          this.floorplan.create(this.config.Floorplans);
        }

        let entertrace = null;
        if (this.project.StartScene.Pitch != null) {
          entertrace = this.project.StartScene;
        } else if (first.StartScene) {
          entertrace = first.StartScene;
        } else {
          entertrace = first.PhotoPoints[0];
        }

        this.prepareScene(first, entertrace);
        this.switchScene(first, first.PhotoPoints[0]);
      }

      this.intro.show(
        this.project,
        this.config.Status,
        this.onClick.bind(this)
      );
      this.hideSpinner();
      // }.bind(this)
      // )
      // .catch(result => {
      //     console.log(result);
      //     this.hideSpinner();
      //     if (result.request && result.request.status == 404) {
      //         this.intro.showLoadFailed();
      //     } else {
      //         this.intro.showLoadFailed(true);
      //     }
      //     console.warn(result);
      // });
    }
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  buildMap(config) {
    if (config.GroupLogoUrl !== null && !config.GroupLogoUrl.includes("http")) {
      config.GroupLogoUrl = window.hubsUrl + "/logo/" + config.GroupLogoUrl;
    }
    if (config.Logo !== null && config.Logo !== "") {
      config.GroupLogoUrl = config.Logo;
    }
    this.project = config;
    const map = {};
    const points = {};
    this.store.photosCount = this.project.Photos.length;
    for (let i = 0; i < this.project.Photos.length; i++) {
      const photo = this.project.Photos[i];

      if (!photo.Filters) {
        photo.Filters = {
          Saturation: 1,
          Contrast: 1,
          Brightness: 1,
        };
      }

      for (let j = 0; j < photo.PhotoPoints.length; j++) {
        const point = photo.PhotoPoints[j];
        points[point.Id] = point;
      }
      map[photo.Id] = {
        ...photo,
        scene: null,
      };
    }
    this.project.map = map;
    this.project.points = points;
    this.project.leadPage = config.IsLeadPage;
  }

  startAutorotate(delay) {
    if (this.autorotate) {
      if (this.autorotateTimer == null) {
        this.autorotateCounter = AUTOROTATE_CHANGE_SCENE_TIME;
        this.autorotateTimer = setInterval(() => {
          var currentTime = performance.now();
          var elapsed = currentTime - this.viewer._idleTimer._startTime;
          var remaining = this.viewer._idleTimer._duration - elapsed;
          var isIdle = remaining + 100 >= this.viewer._idleTimer._duration;
          if (this.autorotateCounter <= 0) {
            this.autorotateChangeScene();
            return false;
          }
          if (isIdle) {
            this.autorotateCounter--;
          } else {
            this.autorotateCounter = AUTOROTATE_CHANGE_SCENE_TIME;
          }
        }, 1000);
      }

      this.viewer.startMovement(AUTOROTATE_MOVEMENT);
      this.viewer.setIdleMovement(delay, AUTOROTATE_MOVEMENT);
    }
  }

  stopAutorotate() {
    if (this.autorotateTimer !== null) {
      clearInterval(this.autorotateTimer);
      this.autorotateTimer = null;
    }
    this.viewer.stopMovement();
    this.viewer.setIdleMovement({});
  }

  autorotateSetDelay(delay) {
    this.viewer._idleTimer._duration = delay;
  }

  autorotateChangeScene() {
    this.stopAutorotate();
    var nextIndex =
      this.store.currentPhotoIndex + 1 >= this.store.photosCount
        ? 0
        : this.store.currentPhotoIndex + 1;
    var next = this.project.Photos[nextIndex];
    this.switchSceneAlternative(next, next.PhotoPoints[0]);
    this.startAutorotate(AUTOROTATE_CHANGE_SCENE_DELAY);
  }

  autorotateActivity() {
    this.stopAutorotate();
    this.startAutorotate(AUTOROTATE_CHANGE_SCENE_DELAY);
  }

  createViewer() {
    this.container.innerHTML = viewportTemplate;
    const element = document.getElementById("viewport");
    const blockElement = function(el) {
      const tryPrevent = function(e) {
        if(e.cancelable) {
          e.preventDefault();
        }
      };
      el.addEventListener('touchmove', tryPrevent, { passive: false });
      el.addEventListener('pointermove', tryPrevent, { passive: false });
      el.style && (el.style.touchAction = "none");
    }
    blockElement(element);
    blockElement(document);
    
    element.style.height = this.container.clientHeight;

    this.hotspots = document.createElement("div");
    this.hotspots.className = "hotspots";
    this.container.appendChild(this.hotspots);
    // Create viewer.
    this.viewer = new Marzipano.Viewer(element, {
      stage: {
        progressive: true,
      },
    });

    this.onRenderStarted = this.renderStarted.bind(this);
    this.viewer.addEventListener("sceneChange", this.onRenderStarted);
    this.currentFov = 0;
    this.logoHotspot = null;

    const tools = {};

    // Create geometry.
    tools.geometry = new Marzipano.CubeGeometry([
      { tileSize: 2048, size: 2048, fallbackOnly: true },
      { tileSize: 4096, size: 4096 },
      { tileSize: 8192, size: 8192 },
    ]);

    this.tools = tools;
  }

  renderStarted() {
    this.autorotateActivity();

    const layer = this.viewer.scene().layer();

    const textureStore = layer.textureStore();
    const view = this.viewer.view();

    function isRenderFinished() {
      if (!view.size() || view.size().width == 0) {
        return false;
      }
      const tileList = [];
      layer.visibleTiles(tileList);
      for (let i = 0; i < tileList.length; i++) {
        if (!textureStore.query(tileList[i]).hasTexture) {
          return false;
        }
      }
      return true;
    }
    function renderFinished() {
      if (isRenderFinished()) {
        this.hideSpinner();
        this.showButtons();
        this.showHotspots();

        this.viewer.removeEventListener("sceneChange", this.onRenderStarted);
        textureStore.removeEventListener("textureLoad", renderFinished);
      }
    }
    if (isRenderFinished()) {
      renderFinished.bind(this)();
      return;
    }
    textureStore.addEventListener("textureLoad", renderFinished.bind(this));
  }

  hideSpinner() {
    const spinner = document.querySelector(".spinner");
    Utils.addClass(spinner, " hidden");
  }

  updateTitle(title) {
    const photoTitle = document.querySelector(".photoname-label");
    photoTitle.textContent = title;
  }

  showHotspots() {
    if (this.intro.active) {
      return;
    }
    const hotspots = document.querySelectorAll(".spot-center");
    for (let i = 0; i < hotspots.length; i++) {
      Utils.addClass(hotspots[i], " visible");
    }
  }

  showMessage(text) {
    const message = document.querySelector(".message");
    message.textContent = text;
    Utils.addClass(message, " visible");
  }

  showButtons() {
    if (this.intro.active) {
      return;
    }
    const back = document.querySelector(".button-back");
    Utils.addClass(back, " visible");
    const controls = document.querySelectorAll(".viewControlElement");
    for (let i = 0; i < controls.length; i++) {
      Utils.addClass(controls[i], " visible");
    }
  }

  loadImage(imageUrl, onprogress) {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      var notifiedNotComputable = false;

      xhr.open("GET", imageUrl, true);
      xhr.responseType = "arraybuffer";

      xhr.onprogress = function (ev) {
        if (ev.lengthComputable) {
          onprogress(parseInt((ev.loaded / ev.total) * 100));
        } else {
          if (!notifiedNotComputable) {
            notifiedNotComputable = true;
            onprogress(-1);
          }
        }
      };

      xhr.onloadend = function () {
        if (!xhr.status.toString().match(/^2/)) {
          reject(xhr);
        } else {
          if (!notifiedNotComputable) {
            onprogress(100);
          }

          var options = {};
          var headers = xhr.getAllResponseHeaders();
          var m = headers.match(/^Content-Type\:\s*(.*?)$/im);

          if (m && m[1]) {
            options.type = m[1];
          }

          var blob = new Blob([this.response], options);

          resolve(window.URL.createObjectURL(blob));
        }
      };

      xhr.send();
    });
  }

  loadFace(index, url) {
    //console.log('xxxx', this.loadFaceStore);

    this.loadFaceStore.elements[index] = {
      loaded: false,
      progress: 0,
    };

    this.loadFaceStore.updateProgress();

    let onprogress = function (ratio) {
      this.loadFaceStore.elements[index].progress = ratio;
      this.loadFaceStore.updateProgress();
    }.bind(this);

    this.loadImage(url, onprogress).then(
      function (imgSrc) {
        this.loadFaceStore.elements[index].loaded = true;
      }.bind(this)
    );
  }

  createScene(url, initial) {
    // Create source.
    let attempt = 0;
    let container = this.container;

    this.loadFaceStore = {
      hideInterval: null,
      elements: [],
      loading: [],
      loaded: [],
      progress: [],
      currentPercent: 0,
      updateProgress: function () {
        function animateValue(obj, start, end, duration, fn) {
          // assumes integer values for start and end

          var range = end - start;
          // no timer shorter than 50ms (not really visible any way)
          var minTimer = 50;
          // calc step time to show all interediate values
          var stepTime = Math.abs(Math.floor(duration / range));

          // never go below minTimer
          stepTime = Math.max(stepTime, minTimer);

          // get current time and calculate desired end time
          var startTime = new Date().getTime();
          var endTime = startTime + duration;
          var timer;

          function run() {
            var now = new Date().getTime();
            var remaining = Math.max((endTime - now) / duration, 0);
            var value = Math.round(end - remaining * range);
            obj.innerText = value + "%";
            if (value == end) {
              clearInterval(timer);
              if (fn !== undefined) {
                fn(obj, end);
              }
            }
          }

          timer = setInterval(run, stepTime);
          run();
        }

        let current = 0;
        let max = 0;

        Object.keys(this.elements).forEach(
          function (index, value) {
            let element = this.elements[index];
            current += element.progress;
            max += 100;
            element.loaded = true;
          }.bind(this)
        );

        let percent = parseFloat((current / max) * 100).toFixed(2);

        //console.log('viewPhotoLoader', viewPhotoLoader);

        let viewPhotoLoader = container.querySelector(".viewPhotoLoader");
        let viewPhotoLoaderValue = viewPhotoLoader.querySelector(".value");

        if (percent <= 1) {
          viewPhotoLoaderValue.innerText = "0%";
        } else if (percent >= 99) {
          viewPhotoLoaderValue.innerText = "100%";
          this.hideInterval = setTimeout(function () {
            Utils.addClass(viewPhotoLoader, " hidden");
          }, 2000);
        } else {
          clearInterval(this.hideInterval);
          viewPhotoLoaderValue.innerText = percent + "%";
          Utils.removeClass(viewPhotoLoader, " hidden");
          this.currentPercent = percent;
        }
      },
    };

    const source = new Marzipano.ImageUrlSource(
      function (tile) {
        const face = tile.face.toUpperCase();
        //if (attempt >= 12) {
        //    this.loadFace(face, url + face);
        //    return {
        //        url: url + face
        //    };
        //}
        //attempt++;

        if (tile.z === 0) {
          this.loadFace("L" + face, url + "L" + face);
          return {
            url: url + "L" + face,
          };
        } else if (tile.z === 1) {
          this.loadFace("H" + face, url + "H" + face);
          return {
            url: url + "H" + face,
          };
        }
      }.bind(this)
    );

    var limiter = Marzipano.RectilinearView.limit.traditional(
      4096,
      (100 * Math.PI) / 180,
      (180 * Math.PI) / 180
    );
    // Create scene.
    return this.viewer.createScene({
      source: source,
      geometry: this.tools.geometry,
      view: new Marzipano.RectilinearView(
        initial || { yaw: (3 / 4) * Math.PI, fov: Math.PI },
        limiter
      ),
      pinFirstLevel: true,
    });
  }

  prepareScene(photo, entrance) {
    if (photo.scene == null) {
      if (entrance) {
        var initial = this.mapPoint(entrance, false);
        photo.scene = this.createScene(photo.ImageUrl, initial);
      } else if (photo.StartScene !== null) {
        var initial = this.mapPoint(photo.StartScene, false);
        photo.scene = this.createScene(photo.ImageUrl, initial);
      } else {
        photo.scene = this.createScene(photo.ImageUrl);
      }

      photo.scene.photoId = photo.Id;
      for (let key in photo.PhotoPoints) {
        if (photo.PhotoPoints.hasOwnProperty(key)) {
          const point = photo.PhotoPoints[key];
          this.createHotspot(photo.scene, point);
        }
      }
      if (this.showNadir) {
        if (
          !this.minimalMode &&
          this.project.GroupLogoUrl !== null &&
          this.project.GroupLogoUrl !== ""
        ) {
          this.createHotspot(photo.scene, {
            Id: 0,
            Type: POINT_LOGO,
          });
        }
      }
    }
  }

  //from remote source
  requestSwitchScene(data, reverse) {
    const points = this.project.map[data.photoId].PhotoPoints;
    const targetId = data.targetId || (points.length > 0 ? points[0].Id : null);
    const target = this.project.points[targetId];
    const photo = this.project.map[data.photoId];
    this.switchScene(photo, target, reverse);
  }

  switchSceneByUser(photo, target, reverse) {
    Bus.post(SCENE_CHANGED, {
      photoId: photo.Id,
      targetId: target?.Id,
      targetPhotoId: target?.TargetPhotoId,
    });
    this.switchScene(photo, target, reverse);
  }

  mapPoint(point, reverse) {
    const pitch = point.pitch || point.Pitch;
    const yaw = point.yaw || point.Yaw;
    return {
      pitch,
      yaw: reverse ? yaw + (180 * Math.PI) / 180 : yaw,
      fov: (120 * Math.PI) / 180,
    };
  }

  setCanvasEffects(canvas, filters) {
    var filterString = "";
    for (var key in filters) {
      filterString =
        filterString + " " + key.toLowerCase() + "(" + filters[key] + ")";
    }
    Object.assign(canvas.style, {
      filter: filterString,
    });
  }

  switchSceneDirect(photo) {
    var afterCall = null;
    if (this.viewer && this.viewer.scene()) {
      var lastScene = this.viewer.scene();

      if (lastScene && lastScene.photoId === photo.Id) {
        if (photo.StartScene) {
          var exit = this.mapPoint(photo.StartScene);
          this.viewer.lookTo(exit, { transitionDuration: 500 });
        }
        return;
      }

      afterCall = function () {
        var lastPhoto = this.project.map[lastScene.photoId];
        lastPhoto.scene = null;
        this.viewer.destroyScene(lastScene);
      }.bind(this);
    }

    if (photo.StartScene) {
      const entertrace = this.mapPoint(photo.StartScene);
      this.prepareScene(photo, entertrace);
    } else {
      this.prepareScene(photo);
    }

    this.animateSwitching(
      photo,
      function () {
        this.updateTitle(photo.Name);
        if (this.floorplan) {
          this.floorplan.switchPoint(photo.Id, true);
        }
        if (typeof afterCall === "function") {
          afterCall();
        }
      }.bind(this)
    );

    this.store.currentPhotoIndex = this.findIndexByPhotoId(photo.Id);
    this.store.currentSceneId = photo.Id;
    //this.store.recentTargetId = target ? target.Id : null;

    this.showHotspots();
  }

  switchScene(photo, target, reverse) {
    var afterCall = null;

    if (this.viewer && this.viewer.scene()) {
      var lastScene = this.viewer.scene();
      afterCall = function () {
        var lastPhoto = this.project.map[lastScene.photoId];
        lastPhoto.scene = null;
        this.viewer.destroyScene(lastScene);
      }.bind(this);
    }

    //this.prepareScene(photo, target);
    this.prepareScene(photo);
    this.updateTitle(photo.Name);

    if (
      this.store.recentTargetId &&
      target &&
      target.Id != this.store.recentTargetId
    ) {
      const exitPoint = this.project.points[target.TargetId];
      const exit = this.mapPoint(exitPoint);
      const position = { ...exit, pitch: 0 };

      let targetView;
      if (photo.StartScene) {
        targetView = this.mapPoint(photo.StartScene);
      } else {
        targetView = this.mapPoint(
          target,
          { reverse },
          { transitionDuration: 0 }
        );
      }
      targetView.pitch = 0;
      photo.scene.view().setParameters(targetView);

      this.viewer.lookTo(
        position,
        { transitionDuration: 500 },
        this.nextTick(() => {
          this.animateSwitching(photo, afterCall);
        })
      );
    } else {
      this.animateSwitching(photo);
    }

    this.store.currentPhotoIndex = this.findIndexByPhotoId(photo.Id);
    this.store.currentSceneId = photo.Id;
    this.store.recentTargetId = target ? target.Id : null;

    if (this.floorplan) {
      this.floorplan.switchPoint(photo.Id, true);
    }

    if (reverse) {
      this.showHotspots();
    }
  }

  findIndexByPhotoId(photoId) {
    var photoIndex = 0;
    this.project.Photos.forEach((photo, index) => {
      if (photo.Id === photoId) {
        photoIndex = index;
      }
    });
    return photoIndex;
  }

  switchSceneAlternative(photo, target, reverse) {
    var afterCall = null;

    if (this.viewer && this.viewer.scene()) {
      var lastScene = this.viewer.scene();
      afterCall = function () {
        var lastPhoto = this.project.map[lastScene.photoId];
        lastPhoto.scene = null;
        this.viewer.destroyScene(lastScene);
      }.bind(this);
    }

    this.prepareScene(photo, target);
    this.updateTitle(photo.Name);

    this.animateSwitching(photo, null, true);

    this.store.currentPhotoIndex = this.findIndexByPhotoId(photo.Id);
    this.store.currentSceneId = photo.Id;
    this.store.recentTargetId = target ? target.Id : null;

    this.showHotspots();
  }

  animateSwitching(photo, done, autorotateMode) {
    this.switching = true;

    const hotspots = document.querySelectorAll(".spot-center");
    for (let i = 0; i < hotspots.length; i++) {
      Utils.addClass(hotspots[i], " switching");
    }

    photo.scene.switchTo(
      {
        transitionDuration: autorotateMode ? 1000 : 500,
        transitionUpdate: (val, newScene, oldScene) => {
          var newLayer = newScene.layer();
          var eased = Easing.easeInOutQuart(val);

          const opf = {
            Brightness: 1,
            Contrast: 1,
            Saturate: 1,
          };
          if (oldScene != null) {
            const oldScenePhoto = this.project.Photos.find(
              (p) => p.Id === oldScene.photoId
            );
            if (oldScenePhoto && oldScenePhoto.Filters) {
              Object.assign(opf, oldScenePhoto.Filters);
            }
          }

          const npf = {
            Brightness: 1,
            Contrast: 1,
            Saturate: 1,
          };
          if (newScene != null) {
            const newScenePhoto = this.project.Photos.find(
              (p) => p.Id === newScene.photoId
            );
            if (newScenePhoto && newScenePhoto.Filters) {
              Object.assign(npf, newScenePhoto.Filters);
            }
          }

          const nf = {
            Brightness:
              opf.Brightness +
              (opf.Brightness > npf.Brightness
                ? (opf.Brightness - npf.Brightness) * -1
                : npf.Brightness - opf.Brightness) *
                val,
            Contrast:
              opf.Contrast +
              (opf.Contrast > npf.Contrast
                ? (opf.Contrast - npf.Contrast) * -1
                : npf.Contrast - opf.Contrast) *
                val,
            Saturate:
              opf.Saturate +
              (opf.Saturate > npf.Saturate
                ? (opf.Saturate - npf.Saturate) * -1
                : npf.Saturate - opf.Saturate) *
                val,
          };
          var canvas = newScene.viewer().domElement().querySelector("canvas");
          this.setCanvasEffects(canvas, nf);

          if (autorotateMode) {
            newLayer.mergeEffects({
              opacity: eased,
            });
          } else {
            newLayer.mergeEffects({
              rect: {
                relativeWidth: eased,
                relativeHeight: eased,
                relativeX: 0.5 - eased / 2,
                relativeY: 0.5 - eased / 2,
              },
              opacity: eased,
            });
            //if (oldScene != null) {
            //    var oldLayer = oldScene.layer();
            //    oldLayer.mergeEffects({
            //        rect: {
            //            relativeWidth: eased,
            //            relativeHeight: eased,
            //            relativeX: 0.5 - eased / 2,
            //            relativeY: 0.5 - eased / 2
            //        },
            //    });
            //}
          }
        },
      },
      () => {
        this.switching = false;

        const hotspots = document.querySelectorAll(".spot-center");
        for (let i = 0; i < hotspots.length; i++) {
          Utils.removeClass(hotspots[i], " switching");
        }

        if (done) {
          done();
        }
      }
    );
  }

  nextTick(callback) {
    return function () {
      setTimeout(callback, 100);
    };
  }

  //unused
  updateViewFromExternalSource(data) {
    const destinationViewParameters = {
      yaw: data.yaw,
      pitch: data.pitch,
      fov: data.fov,
    };

    const options = {
      transitionDuration: 2000,
    };

    this.scene.lookTo(destinationViewParameters, options);
  }

  createHotspot(scene, point) {
    let hotspot = document.createElement("div");
    this.hotspots.appendChild(hotspot);

    switch (point.Type) {
      case POINT_DOOR:
        hotspot.outerHTML =
          // this.config.PhotoPointStyle === 1 &&
          hotspotDoorOnGroundTemplate
          // Math.abs(point.Pitch) > 0.26
          //   ? hotspotDoorOnGroundTemplate
          //   : hotspotDoorTemplate;
        break;
      case POINT_INFO:
        hotspot.outerHTML = hotspotInfoTemplate;
        break;
      case POINT_LOGO:
        hotspot.outerHTML = hotspotLogoTemplate;
        break;
    }

    const hotspots = this.hotspots.getElementsByClassName("hotspot");
    hotspot = hotspots[hotspots.length - 1];
    hotspot.setAttribute("id", point.Id);

    switch (point.Type) {
      case POINT_DOOR:
        const target = this.project.points?.[point.TargetId];
        const photo = this.project.map[target?.PhotoId ?? point.TargetPhotoId];

        if (
          point?.PhotoPointStyle === 1
        ) {
          // 20 degrees = ~0.35 radians
          const rotationAngle = Math.round(
            90 - (Math.abs(point.Pitch > 0.35 ? point.Pitch : 0.35) * 180) / Math.PI
          );

          hotspot.querySelector(
            ".spot-center"
          ).style.transform = `rotateX(${rotationAngle}deg)`;

          Utils.registerEvent(
            hotspot,
            "click",
            function () {
              this.switchSceneByUser(photo, target, true);
            }.bind(this)
          );

          Utils.registerEvent(
            hotspot.querySelector(".hotspot-door-ring"),
            "animationiteration",
            function (e) {
              if (e.target.parentElement.matches(":hover")) {
                e.target.style.animationPlayState = "paused";
              }
            }.bind(this)
          );

          Utils.registerEvent(
            hotspot.querySelector(".spot-center"),
            "pointerleave",
            function (e) {
              const ring = e.target.querySelector(".hotspot-door-ring");
              if (ring.style.animationPlayState === "paused") {
                ring.style.animationPlayState = "running";
              }
            }.bind(this)
          );
        } else {
          hotspot.getElementsByClassName("label")[0].textContent = photo.Name;

          Utils.registerEvent(
            hotspot.querySelector(".spot-center"),
            "click",
            function () {
              this.switchSceneByUser(photo, target, true);
            }.bind(this)
          );

          Utils.registerEvent(
            hotspot,
            "mouseenter",
            function () {
              Utils.addClass(
                hotspot.getElementsByClassName("label")[0],
                "full"
              );
            }.bind(this)
          );

          Utils.registerEvent(
            hotspot,
            "mouseleave",
            function () {
              Utils.removeClass(
                hotspot.getElementsByClassName("label")[0],
                "full"
              );
            }.bind(this)
          );
        }

        scene.hotspotContainer().createHotspot(hotspot, {
          yaw: point.Yaw,
          pitch: point.Pitch,
        });
        break;
      case POINT_INFO:
        const infoContent = hotspot.querySelector(".info-content");

        if (!!point.Info) {
          infoContent.textContent = point.Info;
        } else {
          infoContent.remove();
        }

        if (point.MediaType === "image" && point.PhotoInfoThumbnailUrl) {
          Utils.registerEvent(
            hotspot.getElementsByClassName("info-photo")[0],
            "click",
            function (event) {
              event.preventDefault();
              this.showBigPicture(
                point.PhotoInfoUrl,
                point.VideoLink,
                point.Info,
                point.MediaType
              );
            }.bind(this)
          );

          //hotspot.getElementsByClassName("info-photo")[0].href = point.PhotoInfoUrl;
          hotspot.getElementsByClassName("info-photo-thumb")[0].src =
            point.PhotoInfoThumbnailUrl;
        } else {
          hotspot.getElementsByClassName("info-photo")[0].remove();
        }

        const linkEl = hotspot.querySelector(".info-link-a");
        if (point.MediaType === "link" && !!point.Link) {
          linkEl.href = point.Link;
          // Parameter for walk preview in mobile app
          // Open links in the same tab
          if (Utils.getParameterValue("wv", null) != null) {
            linkEl.target = "_top";
            linkEl.innerText = "Otwórz link";
          }
        } else {
          linkEl.remove();
        }

        if (point.MediaType === "video" && !!point.VideoLink) {
          Utils.registerEvent(
            hotspot.querySelector(".info-video"),
            "click",
            function (event) {
              event.preventDefault();
              this.showBigPicture(
                point.PhotoInfoUrl,
                point.VideoLink,
                point.Info,
                point.MediaType
              );
            }.bind(this)
          );

          this.getVideoThumbnail(point.VideoLink).then((src) => {
            hotspot.querySelector(".info-video-thumb").src = src;
          });
        } else {
          hotspot.querySelector(".info-video").remove();
        }

        const contentContainer = hotspot.querySelector(".info-cloud");
        if (contentContainer.children.length) {
          contentContainer.classList.remove("no-content");
        } else {
          contentContainer.classList.add("no-content");
        }

        scene.hotspotContainer().createHotspot(hotspot, {
          yaw: point.Yaw,
          pitch: point.Pitch,
        });

        break;
      case POINT_LOGO:
        this.logoHotspot = hotspot;

        var url = this.project.GroupLogoUrl;
        hotspot.getElementsByClassName("logo-image")[0].src = url;

        point.Yaw = 0;
        point.Pitch = Math.PI * 0.5;

        scene.hotspotContainer().createHotspot(
          hotspot,
          {
            yaw: point.Yaw,
            pitch: point.Pitch,
          },
          {
            perspective: {
              radius: 512,
            },
          }
        );

        break;
    }
  }

  onClick() {
    //const first = this.getFirst();
    //this.switchScene(first, first.PhotoPoints[0]);
    this.intro.hide(
      function (intro) {
        if (this.floorplan) {
          this.floorplan.show();
        }
      }.bind(this)
    );
    this.showButtons();
    this.showHotspots();
    setTimeout(() => {
      document.querySelector(".viewPhotoRoomList").classList.remove("isOpen");
      document.querySelector(".photoname-button").classList.remove("isOpen");
    }, 500);
    //this.autorotateSetDelay(AUTOROTATE_CHANGE_SCENE_DELAY);
  }

  getFirst() {
    return this.project.Photos[0];
  }

  getPhoto(id) {
    return this.project.Photos.find((photo) => photo.Id === id);
  }

  getStartScene() {
    return this.project.StartScene;
  }

  showBigPicture(imageUrl, videoUrl, info, mediaType) {
    Bus.post(MEDIA_PREVIEW_OPENED);
    if (mediaType === "video") {
      Utils.removeClass(document.querySelector(".video-container"), " hidden");
      const type = this.getPlayerType(videoUrl);
      switch (type) {
        case "youtube": {
          const control = document.querySelector(".youtube");
          let link;
          const source = new URL(videoUrl);
          const videoId = source.searchParams.get("v");
          if (videoId != null) {
            link = new URL("https://www.youtube.com/embed/" + videoId);
          } else {
            link = source;
          }
          link.searchParams.append("enablejsapi", 1);
          control.src = link.href;
          Utils.removeClass(control, " hidden");
          break;
        }
        case "vimeo": {
          const control = document.querySelector(".vimeo");
          let link;
          const source = new URL(videoUrl);
          if (
            source.pathname &&
            source.pathname.split("/").filter((part) => part !== "").length ===
              1
          ) {
            const videoId = source.pathname.substr(1);
            link = new URL("https://player.vimeo.com/video/" + videoId);
          } else {
            link = source;
          }
          link.searchParams.append("title", 0);
          link.searchParams.append("byline", 0);
          link.searchParams.append("portrait", 0);
          control.src = link.href;
          Utils.removeClass(control, " hidden");
          break;
        }
        case "generic": {
          const control = document.querySelector(".generic");
          Utils.removeClass(control, " hidden");
          control.src = videoUrl;
          break;
        }
      }
    } else if (mediaType === "image") {
      Utils.removeClass(document.querySelector(".bigpic-img"), " hidden");
      this.bigpic.getElementsByClassName("bigpic-img")[0].src = imageUrl;
    }
    if (info != null) {
      this.bigpic.getElementsByClassName("bigpic-info")[0].innerHTML = info;
    } else {
      this.bigpic.getElementsByClassName("bigpic-info")[0].innerHTML = "";
    }

    Utils.removeClass(this.bigpic, " hidden");
  }

  hideBigPic() {
    Bus.post(MEDIA_PREVIEW_CLOSED);
    Utils.addClass(this.bigpic, " hidden");
    Utils.addClass(document.querySelector(".video-container"), " hidden");
    Utils.addClass(document.querySelector(".bigpic-img"), " hidden");
    {
      const control = document.querySelector(".youtube");
      control.contentWindow.postMessage(
        '{"event":"command","func":"' + "stopVideo" + '","args":""}',
        "*"
      );
      control.src = "about:blank";
      Utils.addClass(control, " hidden");
    }
    {
      const control = document.querySelector(".vimeo");
      control.contentWindow.postMessage({ method: "pause" }, "*");
      control.src = "about:blank";
      Utils.addClass(control, " hidden");
    }
    {
      const control = document.querySelector(".generic");
      control.pause();
      control.src = null;
      Utils.addClass(control, " hidden");
    }
  }

  getPlayerType(url) {
    if (url.indexOf("youtu") >= 0) return "youtube";
    if (url.indexOf("vimeo") >= 0) return "vimeo";
    return "generic";
  }

  async getVideoThumbnail(url) {
    const videoType = this.getPlayerType(url);
    switch (videoType) {
      case "youtube":
        const regExp =
          /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return `https://img.youtube.com/vi/${match[1]}/0.jpg`;
      case "vimeo":
        const videoData = await fetch(
          `https://vimeo.com/api/oembed.json?url=${url}`
        );
        const thumbnailUrl = (await videoData.json()).thumbnail_url.split("_");
        thumbnailUrl.splice(-1);
        return `${thumbnailUrl}_420`;
      default:
        return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeAAAAEOCAIAAADe+FMwAAABhWlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9TtaIVB4uIOGSoThakijhKFYtgobQVWnUwufRDaNKQpLg4Cq4FBz8Wqw4uzro6uAqC4AeIo5OToouU+L+k0CLGg+N+vLv3uHsHCPUyU82OCUDVLCMVj4nZ3IoYeEUXBtGLAKISM/VEeiEDz/F1Dx9f7yI8y/vcn6NPyZsM8InEs0w3LOJ14ulNS+e8TxxiJUkhPiceN+iCxI9cl11+41x0WOCZISOTmiMOEYvFNpbbmJUMlXiKOKyoGuULWZcVzluc1XKVNe/JXxjMa8tprtMcQRyLSCAJETKq2EAZFiK0aqSYSNF+zMM/7PiT5JLJtQFGjnlUoEJy/OB/8LtbszAZdZOCMaDzxbY/RoHALtCo2fb3sW03TgD/M3CltfyVOjDzSXqtpYWPgP5t4OK6pcl7wOUOMPSkS4bkSH6aQqEAvJ/RN+WAgVugZ9XtrbmP0wcgQ10t3QAHh8BYkbLXPN7d3d7bv2ea/f0AfwxyrPgI/pUAAAAJcEhZcwAALiMAAC4jAXilP3YAAAAHdElNRQfmBhYMCAbeW3mFAAAC6UlEQVR42u3UMREAAAjEMMD028cFx5BI6NBOUgD8MxIAGDQABg1g0AAYNIBBA2DQABg0gEEDYNAABg2AQQNg0AAGDYBBAxg0AAYNYNAAGDQABg1g0AAYNIBBA2DQABg0gEEDYNAABg2AQQMYNAAGDYBBAxg0AAYNYNAAGDQABg1g0AAYNIBBA2DQAAYNgEEDYNAABg2AQQMYNAAGDYBBAxg0AAYNYNAAGDQABg1g0AAYNIBBA2DQAAYNgEEDYNAABg2AQQMYNAAGDYBBAxg0AAYNYNAAGDSAQQNg0AAYNIBBA2DQAAYNgEEDYNAABg2AQQMYNAAGDWDQABg0AAYNYNAAGDSAQQNg0AAYNIBBA2DQAAYNgEEDYNAABg2AQQMYNAAGDWDQABg0AAYNYNAAGDSAQQNg0AAYNIBBA2DQAAYNgEEDGDQABg2AQQMYNAAGDWDQABg0AAYNYNAAGDSAQQNg0AAGDYBBA2DQAAYNgEEDGDQABg2AQQMYNAAGDWDQABg0AAYNYNAAGDSAQQNg0AAGDYBBA2DQAAYNgEEDGDQABg2AQQMYNAAGDWDQABg0gEEDYNAAGDSAQQNg0AAGDYBBA2DQAAYNgEEDGDQABg1g0AAYNAAGDWDQABg0gEEDYNAAGDSAQQNg0AAGDYBBA2DQAAYNgEEDGDQABg1g0AAYNAAGDWDQABg0gEEDYNAAGDSAQQNg0AAGDYBBAxg0AAYNgEEDGDQABg1g0AAYNAAGDWDQABg0gEEDYNAABg2AQQNg0AAGDYBBAxg0AAYNgEEDGDQABg1g0AAYNAAGDWDQABg0gEEDYNAABg2AQQNg0AAGDYBBAxg0AAYNgEEDGDQABg1g0AAYNIBBA2DQABg0gEEDYNAABg2AQQNg0AAGDYBBAxg0AAYNYNAAGDQABg1g0AAYNIBBA2DQABg0gEEDYNAABg2AQQMYtAQABg2AQQMYNAAGDWDQABg0AAYNYNAAGDSAQQNwbQEdDQOBgCfUuAAAAABJRU5ErkJggg==";
    }
  }

  // WALK NAVIGATOR
  setupNavigator() {
    if (!this.config.UserId) return;

    const navigatorBtn = document.querySelector(".photoname-button");
    const navigator = document.querySelector(".viewPhotoRoomList");

    navigatorBtn.classList.remove("disabled");
    navigatorBtn.addEventListener("click", () => {
      navigatorBtn.classList.toggle("isOpen");
    });

    this.config?.Photos?.forEach(async (photo) => {
      const sceneThumbnail = document.createElement("button");
      sceneThumbnail.classList.add("viewPhotoRoomListItem");
      sceneThumbnail.addEventListener("click", () => {
        this.switchSceneByUser(photo, null, true);
      });

      const photoLabel = document.createElement("small");
      photoLabel.classList.add("viewPhotoRoomListItem__label");
      photoLabel.innerText = photo.Name;
      sceneThumbnail.appendChild(photoLabel);

      const photoEl = document.createElement("img");
      const thumbnail = await this.getPhotoThumbail(photo.Id);
      const url = thumbnail; // ?? photo.ImageUrl;
      photoEl.setAttribute("src", url);
      sceneThumbnail.appendChild(photoEl);

      navigator.appendChild(sceneThumbnail);
    });

    navigatorBtn.addEventListener("click", () => {
      navigator.classList.toggle("isOpen");
    });
  }

  async getPhotoThumbail(photoId) {
    try {
      const res = await fetch(
        `${window.apiUrl}/api/folder/${this.projectId}/thumbnail/${photoId}/fetch?userId=${this.config.UserId}`
      );
      if (!res.ok) throw new Error(res.status);
      const blob = await res.blob();
      const url = URL.createObjectURL(blob);

      return url;
    } catch (error) {
      console.log("ERROR", error);
      return null;
    }
  }
}
